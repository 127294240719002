(function(angular){

  'use strict';

  // Load module
  var ngCarbuApi = angular.module('ngCarbuApi');


  ngCarbuApi.filter('requestsData', ['$filter', function( $filter ) {

    /**
     * Filter requests
     *
     * @param array requests
     * @return array
     */
    return function(requests) {

      var data = requests;

      /**
       * Parse Offer
       *
       * @param request
       * @returns {Object}
       * @private
       */
      function _parse( request ) {

        request.user = $filter('userData')(request.user);
        request.offers = $filter('offersData')(request.offers);

        if (angular.isObject(request.product)) {
          angular.extend(request.product,
            {
              title: request.product.title || request.product.name,
              type: request.product.type || request.product.category,
              quantity: request.quantity
            }
          );

          //Resolve product for offers
          angular.forEach(request.offers, function(obj, key) {
            request.offers[key].product = request.product;
          });
        }

        return request;
      }

      if (angular.isArray(requests)) {
        data = [];

        angular.forEach(requests, function(obj, key) {
          this.push(_parse(obj));
        }, data);

      }
      else if (angular.isObject(requests)) {
        data = _parse(requests);
      }

      return data;

    };

  }]);

})(angular);
