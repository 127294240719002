(function(angular){
  'use strict';

  var ngCarbuApi = angular.module('ngCarbuApi');

  //Configuration Resources
  ngCarbuApi.factory('$carbuApiResource', [ '$resource', '$carbuApi', '$log', '$q', '$http', '$httpParamSerializer', function ($resource, $carbuApi, $log, $q, $http, $httpParamSerializer ) {

    var rest = $carbuApi.getRest();
    var resourceUrl = rest.url + rest.namespace;

    var resources = {
      properties: $resource(resourceUrl + rest.routes.properties,
        null,
        {get: {
          method: 'GET',
          params:{},
          cache: true,
          cancellable: false
        }}
      ),
      locations: $resource(resourceUrl + rest.routes.locations,
        null,
        {query: {
          method: 'GET',
          params:{},
          isArray: true,
          cache: false,
          cancellable: true
        }}
      ),
      products: $resource(resourceUrl + rest.routes.products,
        null,
        {query: {
          method: 'GET',
          params:{},
          isArray: true,
          cache: true,
          cancellable: true
        }}
      ),
      offers: $resource(resourceUrl + rest.routes.offers + '/:key',
        {key: '@key'},
        {
          get: {
            method: 'GET',
            isArray: false,
            cache: false,
            cancellable: false
          },
          query: {
            method: 'GET',
            params:{},
            isArray: true,
            cache: false,
            cancellable: true
          }
        }
      ),
      requests: $resource(resourceUrl + rest.routes.requests +  '/:key',
        {key: '@key'},
        {
          get: {
            method: 'GET',
            isArray: false,
            cache: false,
            cancellable: false
          },
          query: {
            method: 'GET',
            params:{},
            isArray: true,
            cache: false,
            cancellable: true
          },
          save: {
            method: 'POST',
            cache: false,
            cancellable: false
          }
        }
      ),
      orders: $resource(resourceUrl + rest.routes.orders + '/:key',
        {key: '@key'},
        {
          get: {
            method: 'GET',
            isArray: false,
            cache: false,
            cancellable: false
          },
          save: {
            method: 'POST',
            cache: false,
            cancellable: false
          }
        }
      ),
      users: $resource(resourceUrl + rest.routes.users.base + '/:id/:actionId',
        {id: '@id', actionId: '@actionId'},
        {
          get: {
            method: 'GET',
            isArray: false,
            cache: false,
            cancellable: true
          },
          update: {
            method: 'POST',
            cache: false,
            cancellable: false
          },
          save: {
            method: 'POST',
            cache: false,
            cancellable: false
          },
          delete: {
            method: 'POST',
            cache: false,
            cancellable: false
          }
        }
      ),
      articles: $resource(resourceUrl + rest.routes.articles + '/:cat/:key',
        {cat: '@cat', key: '@key'},
        {
          get: {
            method: 'GET',
            isArray: false,
            cache: true,
            cancellable: false
          },
          query: {
            method: 'GET',
            params:{},
            isArray: true,
            cache: true,
            cancellable: true
          }
        }
      )
    };

    return resources;

  }]);

})(angular);
