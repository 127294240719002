(function(angular){
  'use strict';

  var app = angular.module('app.root');

  //Configuration Service
  //Service load language file with revision file name. Resolve the browser cache.
  app.factory('asyncLangService', [ 'APP_CONFIG', '$q', '$http', function (APP_CONFIG, $q, $http ) {

    /**
     * Get language keys by url
     * @param url
     * @param options
     * @returns {*}
     * @private
     */
    function _get_language_keys_by_url(url, options) {
      var deferred = $q.defer();

      $http.get(url, options.$http).then(
        function(result_success) {
          deferred.resolve(result_success.data);
        },
        function(result_error) {
          deferred.resolve({});
        }
      );

      return deferred.promise;
    }

    /**
     * Get All language keys from all sources
     *
     * @param options
     * @returns {*}
     * @private
     */
    function _get_all_language_keys(options) {

      var deferred = $q.defer();

      var locale = APP_CONFIG.locale.available[options.key];


      /* Promise Host locale */
      var get_host_language_keys = _get_language_keys_by_url(APP_CONFIG.distUrl + 'lang/' + locale.file, options);

      /* Promise Api language */
      var api_options = angular.copy(options);
      angular.extend(api_options.$http, {params: {language: locale.code.toUpperCase()}});
      var get_api_language_keys = _get_language_keys_by_url(APP_CONFIG.api.url + APP_CONFIG.api.namespace +  APP_CONFIG.api.routes.languagekeys, api_options);

      /* Get all promise language */
      $q.all([get_host_language_keys, get_api_language_keys]).then(
        function(result) {

          /* Override all keys by extend first object in array*/
          for (var i = 1; i < result.length; i++){
            angular.extend(result[0], result[i]);
          }

          deferred.resolve(result[0]);
        },
        function(result) {
          deferred.resolve({});
        }
      );

      return deferred.promise;
    }



    return function (options) {

      var deferred = $q.defer();
      var translations = {};

      //Check if config has available locale
      if (angular.isObject(APP_CONFIG.locale) && angular.isObject(APP_CONFIG.locale.available) && APP_CONFIG.locale.available[options.key]) {

        _get_all_language_keys(options).then(
          function (result) {

            angular.forEach(result, function (val, key) {

              //If empty value set to null to resolve default language
              translations[key] = (!val || val==='') ? null : val;
            });

            deferred.resolve(translations);
          },
          function (result) {
            deferred.reject(options.key);
          }
        );

      }
      else {
        deferred.reject(options.key);
      }

      return deferred.promise;
    };

  }]);

})(angular);
