(function(angular){
  'use strict';

  // Load module
  var pageOrder = angular.module('app.pages.order');

  pageOrder.controller('orderCheckoutCtrl', [
    '$scope',
    '$log',
    '$timeout',
    '$filter',
    '$rootService',
    '$state',
    '$carbuApiOrders',
    'templateUrls',
    'globalTemplateUrls',
    'offerObj',
    'authObj',
    function ( $scope, $log, $timeout, $filter, $rootService, $state, $carbuApiOrders, templateUrls, globalTemplateUrls, offerObj, authObj ) {

      var checkout = this;

      /**
       * Initialize Controller
       */
      function init() {

        checkout.templateUrls = templateUrls;
        checkout.globalTemplateUrls = globalTemplateUrls;

        //Init Auth and Offer
        checkout.auth = authObj;
        checkout.offer = offerObj;

        checkout.offer.paymentMethods = $filter('filter')(checkout.offer.paymentMethods, {enable: true});

        //Init Checkout Order
        checkout.user = {};
        checkout.info = {};
        checkout.ship = {};
        checkout.order = $rootService.getOrder() || {}; //Load order checkout to restore checkout order (if user change state and come back)
        checkout.state = 0;
        checkout.states = [
          {name: 'user', valid: false, view: ''},
          {name: 'info', valid: false},
          {name: 'ship', valid: false},
          {name: 'order', valid: false}
        ];

        checkout.order.offerKey = checkout.offer.key;

        //Set ship city and phone
        angular.extend(checkout.order, {
          city: checkout.offer.location.name
        });

        //Set phone if empty
        if (!checkout.order.phone) {
          checkout.order.phone =  $rootService.getConfig('fields.phone').toString();
        }


        //Set paymentMethod if only one
        if (angular.isArray(checkout.offer.paymentMethods) && checkout.offer.paymentMethods.length === 1) {
          checkout.order.paymentMethod =  checkout.offer.paymentMethods[0].id;
        }
        else if (checkout.order.paymentMethod && !$filter('filter')(checkout.offer.paymentMethods, {id: checkout.order.paymentMethod}).length) {
          delete checkout.order.paymentMethod;
        }

        //Resolve authentication state
        if (checkout.auth.isLoggedIn) {

          //Set User
          angular.extend(checkout.order, checkout.auth.data);
          checkout.states[checkout.state].valid = true;

          //Save order checkout state user
          $rootService.setOrder(checkout.order, true);

          //Go next state
          checkout.state = 1;
        }

        //Set activeState reference to checkout.states[checkout.state]
        checkout.activeState = checkout.states[checkout.state];
      }


      /**
       * Send Order
       */
      this.sendOrder = function () {

        checkout.nextState();

        if( checkout.states[checkout.state].valid ) {

          $carbuApiOrders.saveOrder(checkout.order).then(
            function (success) {
              $rootService.setOrder();
              $rootService.setCart();

              checkout.state = 0;

              if (checkout.auth.isLoggedIn) {
                $state.go('order.confirm', {key: success.data.key}, {location: 'replace'});
              }
              else {
                $state.go('order.pending', {order: success}, {location: 'replace'});
              }
            },
            function (error) {
              $rootService.setCart();

              checkout.state = 0;
              checkout.offer = null;

              $state.go('order.error', {order: error}, {location: 'replace'});
            }
          );
        }
      };

      /**
       * Next State
       */
      this.nextState = function (fields, force_next) {

        var state_name = checkout.states[checkout.state].name;
        var form_name = state_name + 'Form';

        //Extend checkout state fields model (for dynamicly change form fields example used for resolve user fields)
        if (angular.isObject(fields)) {
          angular.extend(checkout.order, fields);

          //Force update checkout model.
          $scope.$apply();
        }

        //Force Check validation form
        checkout[form_name].$setTouched();
        checkout[form_name].$validate();

        if (checkout[form_name].$valid || force_next === true) {
          checkout.states[checkout.state].valid = true;

          if( (checkout.state + 1) < checkout.states.length ) {

            //Save order checkout state
            $rootService.setOrder(checkout.order, true);

            checkout.state++;
            checkout.states[checkout.state].valid = false;
            checkout.activeState = checkout.states[checkout.state];
          }
        }
        else {
          checkout.states[checkout.state].valid = false;
        }
      };


      /**
       * Edit State
       *
       * @param state
       */
      this.editState = function ( to_state ) {

        if (!checkout.states[to_state].valid) {
          return;
        }

        checkout.state = to_state;

        angular.forEach(checkout.states, function (state, key) {
          if (key >= to_state) {
            state.valid = false;
          }
        });
      };


      //On Auth Change
      $scope.$on('rootAuthChange', function (event, new_auth, old_auth) {

        if (checkout.offer) {
          checkout.auth = new_auth;

          if (checkout.auth.data) {

            checkout.nextState(checkout.auth.data, true);
          }
          else {
            $log.error(new_auth);
            $log.error(old_auth);
          }
        }
        else {
          $log.error(checkout);
        }
      });

      //Initialize
      init();

    }
  ]);

})(angular);
