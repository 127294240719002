(function(angular){
  'use strict';

  // Load module
  var pageOffers = angular.module('app.pages.offers');

  pageOffers.controller('offersCtrl', [
    '$scope',
    '$log',
    '$timeout',
    '$filter',
    '$state',
    'templateUrls',
    'dialogsTemplateUrls',
    'offersObj',
    'locationObj',
    'configObj',
    function ( $scope, $log, $timeout, $filter, $state, templateUrls, dialogsTemplateUrls, offersObj, locationObj, configObj) {

    var offers = this;

    function init() {

      offers.sidebar = {
        active: false
      };

      offers.templateUrls = templateUrls;
      offers.dialogsTemplateUrls = dialogsTemplateUrls;

      offers.location = locationObj;
      offers.items = offersObj;
      offers.floatButton = {
        show: configObj.floatButtons.default.show || false,
        delay: configObj.floatButtons.default.delay || 2000,
        min:  (configObj.floatButtons.offers && configObj.floatButtons.offers.min ? configObj.floatButtons.offers.min : 3)
      };

      offers.floatButton.delay = ((offers.items.length >= offers.floatButton.min) && configObj.floatButtons.offers && configObj.floatButtons.offers.delay ? configObj.floatButtons.offers.delay : 5000 );

      //Display float button after timeout.
      if (offers.items.length > 0) {
        $timeout(function () {
          offers.floatButton.show = true;
        }, offers.floatButton.delay);
      }
    }

    //Initialize
    init();

  }]);

})(angular);
