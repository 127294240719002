//Angular bootstrap application with modules.
(function (angular) {
  'use strict';

  var app = angular.module('app.root');


  //Template Urls Constant
  app.constant('TEMPLATE_URLS', {
    components: {
      search: {
        search: "components/search/search.tpl.html",
        searchInline: "components/search/search-inline.tpl.html",
        searchMobile: "components/search/search-mobile.tpl.html"
      },
      auth: {
        signin: "components/auth/signin.tpl.html"
      }
    },
    dialogs: {
      auth: {
        signin: "dialogs/auth/signin.tpl.html",
        signup: "dialogs/auth/signup.tpl.html",
        confirm: "dialogs/auth/confirm.tpl.html",
        forgottenPassword: "dialogs/auth/forgotten-password.tpl.html",
        resetPassword: "dialogs/auth/reset-password.tpl.html"
      },
      offer: {
        details: "dialogs/offer/details.tpl.html"
      },
      contactSupplier: "dialogs/contact-supplier.tpl.html"
    },
    global: {
      terms: "global/terms.tpl.html",
      newsAlert: "global/news-alert.tpl.html",
      headerOrder: "global/header-order.tpl.html",
      order: {
        details: "global/order/details.tpl.html",
        info: "global/order/info.tpl.html",
        ship: "global/order/ship.tpl.html",
        total: "global/order/total.tpl.html"
      }
    },
    menus:{
      account: "menus/account.tpl.html"
    },
    pages: {
      account: {
        offers: {
          request: {
            main: "pages/account/offers/request/main.tpl.html"
          }
        },
        orders: {
          order: {
            main: "pages/account/orders/order/main.tpl.html"
          }
        }
      },
      offers: {
       offer: {
         main: "pages/offers/offer/main.tpl.html"
       },
       offerPersonal: {
         floatButtonTooltip: "pages/offers/offer-personal/float-button-tooltip.tpl.html",
         form: "pages/offers/offer-personal/form.tpl.html",
         hotdog: "pages/offers/offer-personal/hotdog.tpl.html",
         hotdogDialog: "pages/offers/offer-personal/hotdog-dialog.tpl.html",
         pizza: "pages/offers/offer-personal/pizza.tpl.html"
       }
      },
      order: {
       checkout: {
         preview: {
           info: "pages/order/checkout/preview/info.tpl.html",
           ship: "pages/order/checkout/preview/ship.tpl.html"
         },
         states: {
           auth: "pages/order/checkout/states/auth.tpl.html",
           info: "pages/order/checkout/states/info.tpl.html",
           order: "pages/order/checkout/states/order.tpl.html",
           ship: "pages/order/checkout/states/ship.tpl.html"
         },
         details: "pages/order/checkout/details.tpl.html",
         detailsMobile: "pages/order/checkout/details-mobile.tpl.html"
       },
       parts: {
         info: "pages/order/parts/info.tpl.html",
         details: "pages/order/parts/details.tpl.html",
         ship: "pages/order/parts/ship.tpl.html",
         total: "pages/order/parts/total.tpl.html"
       }
      }
    },
    posts: {
      post: "posts/post.tpl.html",
      page: "posts/page.tpl.html",
      elements: {
        arguments: "posts/elements/arguments.tpl.html",
        lastTestimonials: "posts/elements/last-testimonials.tpl.html",
        lastArticles: "posts/elements/last-articles.tpl.html",
        articles: "posts/elements/articles.tpl.html",
        article: "posts/elements/article.tpl.html",
        contact: "posts/elements/contact.tpl.html",
        content: "posts/elements/content.tpl.html",
        chart: "posts/elements/chart.tpl.html"
      }
    }
  });

}(angular));
