(function(angular){
  'use strict';

  // Load module
  var pageAccount = angular.module('app.pages.account');

  pageAccount.controller('accountDashboardCtrl', [
    '$scope',
    'requestsObj', 'offersTemplateUrls',
    'ordersObj', 'ordersTemplateUrls', function ( $scope, requestsObj, offersTemplateUrls, ordersObj, ordersTemplateUrls ) {

    var dashboard = this;

    function init() {

      dashboard.requests = requestsObj;
      dashboard.offersTemplateUrls = offersTemplateUrls;

      dashboard.orders = ordersObj;
      dashboard.ordersTemplateUrls = ordersTemplateUrls;

    }

    //Initialize
    init();

  }]);

})(angular);
