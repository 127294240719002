(function(angular){

  'use strict';

  // Create module
  var pageHome = angular.module('app.pages.home', [
    'ui.router',
    'ismobile'
  ]);


  //This configures the routes and associates each route with a view and a controller
  pageHome.config(['APP_CONFIG', '$stateProvider', function (APP_CONFIG, $stateProvider) {

    var home_state = {
      name: 'home',
      parent: 'main',
      url: '',
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/home/main.tpl.html',
          controller: 'homeCtrl',
          controllerAs: 'home'
        },
        'search@home': {
          name: 'home.search',
          templateUrl: APP_CONFIG.templateUrl + 'pages/home/search.tpl.html'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.home');
        }],
        postObj: ['$q', '$transition$', '$stateParams', '$post', function ($q, $transition$, $stateParams, $post) {

          var to_state = $transition$.to();
          var deferred = $q.defer();

          //Resolve always true
          $post.get(to_state.name, $stateParams).then(
            function(success) {
              deferred.resolve(success);
            },
            function (error) {
              deferred.resolve({});
            }
          );

          return deferred.promise;
        }]
      }
    };

    $stateProvider.state(home_state);
}]);

})(angular);
