(function(angular){

  'use strict';

  // Create module
  var ngCarbuApi = angular.module('ngCarbuApi', [
    'ngResource'
  ]);

  //Constant
  ngCarbuApi.constant('$carbuApiConst', {
      'status' : {
        open: 'OPEN',
        pending: 'PENDING',
        closed: 'CLOSED',
        expired: 'EXPIRED',
        canceled: 'CANCELED'
      }
    }
  );


  //Configure Http Interceptor
  ngCarbuApi.config(['$resourceProvider', '$httpProvider',  function($resourceProvider, $httpProvider) {

    $resourceProvider.defaults.cancellable = true;
    $httpProvider.interceptors.push('$carbuApiHttpInterceptor');
  }]);


  // Provider
  ngCarbuApi.provider('$carbuApi', [ function $carbuApi() {

    var default_config = {
      name: 'carbuApi',
      verbose: false,
      mock: [],
      sessionType: 'sessionStorage',
      appId: {
        key: 'X-App-Id',
        val:'',
        inHeader: true
      },
      accessToken: {
        key: 'X-Api-Key',
        val:'',
        inHeader: true
      },
      rest: {
        url: 'localhost',
        namespace: '/v1',
        routes: {}
      },
      lang: {
        key: 'X-Lang',
        val:'',
        inHeader: true
      }
    };

    this.name = default_config.name;
    this.verbose = default_config.verbose;
    this.mock = default_config.mock;
    this.sessionType = default_config.sessionType;
    this.appId = default_config.appId;
    this.accessToken = default_config.accessToken;
    this.rest = default_config.rest;
    this.lang = default_config.lang;


    this.$get = [ '$http', function( $http ) {
      var name = this.name;
      var verbose = this.verbose;
      var mock = this.mock;
      var session_type = this.sessionType;
      var app_id = this.appId;
      var access_token = this.accessToken;
      var rest = this.rest;
      var lang = this.lang;

      $http.defaults.useXDomain = true;

      return {
        getName: function() {
          return name;
        },
        getVerbose: function() {
          return verbose;
        },
        getMock: function() {
          return mock;
        },
        getSessionType: function() {
          return session_type;
        },
        getAppId: function() {
          return angular.copy(app_id);
        },
        getAccessToken: function() {
          return angular.copy(access_token);
        },
        getRest: function() {
          return angular.copy(rest);
        },
        getLang: function() {
          return angular.copy(lang);
        },
        setLang: function ( val ) {
          lang.val = val;
        },
        setAppId: function ( val ) {
          app_id.val = val;
        },
        setAccessToken: function ( val ) {
          access_token.val = val;
        }
      };
    }];

    this.setAppId = function(app_id) {

      this.appId.key = app_id.key || default_config.appId.key;
      this.appId.val = app_id.val || default_config.appId.val;
      this.appId.inHeader = angular.isDefined(app_id.inHeader) ? app_id.inHeader : default_config.appId.inHeader;
    };

    this.setAccessToken = function(access_token) {

      this.accessToken.key = access_token.key || default_config.accessToken.key;
      this.accessToken.val = access_token.val || default_config.accessToken.val;
      this.accessToken.inHeader = angular.isDefined(access_token.inHeader) ? access_token.inHeader : default_config.accessToken.inHeader;
    };

    this.setRest = function(rest) {

      this.rest.url = rest.url || default_config.rest.url;
      this.rest.namespace = rest.namespace || default_config.rest.namespace;
      this.rest.routes = rest.routes || default_config.rest.routes;
    };

    this.setLang = function(lang) {

      this.lang.key = lang.key || default_config.lang.key;
      this.lang.val = lang.val || default_config.lang.val;
      this.lang.inHeader = angular.isDefined(lang.inHeader) ? lang.inHeader : default_config.lang.inHeader;
    };

    this.setName = function(name) {
      this.name = name || default_config.name;
    };

    this.setVerbose = function(verbose) {
      this.verbose = verbose || default_config.verbose;
    };

    this.setMock = function(mock) {
      this.mock = mock || default_config.mock;
    };

    this.setSessionType = function(session_type) {
      this.sessionType = session_type || default_config.sessionType;
    };

  }]);


  // Http Interceptor
  ngCarbuApi.factory('$carbuApiHttpInterceptor', ['$injector', '$q', '$log', function($injector, $q, $log){

    //Check Url matching
    function _matching_url( url, match_url ) {

      if (!angular.isString(url) && !url.length) {
        return false;
      }

      return url.indexOf(match_url) >= 0;
    }


    function is_replace_data (response) {

      if (response.config.method === 'GET') {
        return true;
      }

      return false;
    }

    //Mock data with json file
    function _mock_data( url, mock_routes, response, lang, replace_data ) {

      var url_route = response.config.url.split('?');
      var url_query = [];
      if (url_route[1]) {
        angular.forEach(url_route[1].split('&'), function(part) {
          var item = part.split("=");

          if (item[0] !== 'userKey' && item[0] !== 'key') {

            this.push(item[0] + '_' + decodeURIComponent(item[1]));
          }
        }, url_query);
      }

      var mock_route = url_route[0].replace(url + '/', '').replace('/', '-');

      $log.debug('Mock Api Route: ' + mock_route);

      //Mock api data
      if (mock_routes.indexOf(mock_route) !== -1) {

        var $http = $injector.get("$http");
        var deferred = $q.defer();

        //Mock Data
        var mock_file = mock_route + (angular.isString(lang) ? '_' + lang : '') + (url_query.length ? '_' + url_query.join('_') : '') + '.json';

        $http.get('/mock-data/' + mock_file, {cache: response.config.cache}).then(
          function (success) {

            var data_success = (replace_data === true && success.data.data ? success.data.data : success.data);
            var data_response = (response.status < 400 ? response.data : (angular.isArray(data_success) ? [] : {}));

            if ( angular.isArray(data_success) || angular.isObject(data_success)) {

              //First isArray (isObject return true if is an array)
              if (angular.isArray(data_success) ) {
                success.data = data_success;
              }
              else {
                success.data = angular.extend({}, data_response, data_success);
              }

              $log.info('Mocked Data Success for: ' + mock_route);
              $log.info(success);

              deferred.resolve(success);
            }
            else {
              $log.warn('Mocked Data Error for: ' + mock_route);
              $log.warn(success);
              $log.warn(response);

              if (response.status >= 400) {
                deferred.reject(response);
              }
              else {
                deferred.resolve(response);
              }
            }
          },
          function (error) {
            $log.warn('Mocked Data Error for: ' + mock_route);
            $log.warn(error);
            $log.warn(response);

            if (response.status >= 400) {
              deferred.reject(response);
            }
            else {
              deferred.resolve(response);
            }
          }
        );

        return deferred.promise;
      }

      return false;
    }


    return {
      request: function (request) {

        var $carbuApi = $injector.get("$carbuApi");
        var api_url = $carbuApi.getRest().url + $carbuApi.getRest().namespace;

        if (_matching_url(request.url, api_url)) {

          var add_param = [];

          var api_lang = $carbuApi.getLang();
          api_lang.val = api_lang.val.toUpperCase();

          add_param.push(api_lang);
          add_param.push($carbuApi.getAccessToken());
          add_param.push($carbuApi.getAppId());

          //Default request
          if (add_param.length > 0) {
            request.params = request.params || {};
            request.headers = request.headers || {};
          }

          angular.forEach(add_param, function(param, key) {

            if(param.key && param.val.length > 0 ) {

              if (!param.inHeader && (!request.params || !request.params[param.key])) {
                request.params[param.key] = param.val;
              }
              else if (param.inHeader && !request.headers[param.key]) {
                request.headers[param.key] = param.val;
              }
            }
          });

          //Verbose Mode
          if ($carbuApi.getVerbose() === true) {
            request.params.verbose = 1;
          }

          // Disable IE ajax request caching
          //request.headers['If-Modified-Since']  = '0';
          //Disable caching
          //request.headers['cache-control']      = 'private, max-age=0, no-cache';
        }

        return request;
      },
      response: function(response) {

        var $carbuApi = $injector.get("$carbuApi");
        var api_url = $carbuApi.getRest().url + $carbuApi.getRest().namespace;

        //Mock data api and compatibility resource for GET Method ressource
        if (_matching_url(response.config.url, api_url)) {

          var replaced = is_replace_data(response, false);

          if (replaced === true) {
            response.data = response.data.data ? response.data.data : response.data;
          }

          var deferred = _mock_data(api_url, $carbuApi.getMock(), response, $carbuApi.getLang().val, replaced);

          if (deferred) {
            return deferred;
          }
        }

        return response;
      },
      responseError : function(response) {

        try {
          //Mock data api
          var $carbuApi = $injector.get("$carbuApi");
          var api_url = $carbuApi.getRest().url + $carbuApi.getRest().namespace;

          if (_matching_url(response.config.url, api_url)) {


            var replaced = is_replace_data(response, true);

            if (replaced === true) {
              response.data = undefined;
            }

            var deferred = _mock_data(api_url, $carbuApi.getMock(), response, $carbuApi.getLang().val, replaced);

            if (deferred) {
              return deferred;
            }
          }

        }
        catch (e) {
          $log.error(e);
        }

        return $q.reject(response);
      }
    };

  }]);


})(angular);
