(function(angular){
  'use strict';

  // Load module
  var pageOrder = angular.module('app.pages.order');

  pageOrder.controller('orderPendingCtrl', ['$scope', '$filter', 'templateUrls', 'orderObj', function ( $scope, $filter, templateUrls, orderObj ) {

    var pending = this;

    function init() {

      pending.templateUrls = templateUrls;
      pending.order = $filter('ordersData')(orderObj.data);
      pending.status = orderObj.status;
    }

    //Initialize
    init();

  }]);

})(angular);
