(function(angular){
  'use strict';

  var app = angular.module('app.root');

  app.controller('headerCtrl', ['$scope', '$log', '$rootService', '$state', 'templateUrls', 'authObj', function ( $scope, $log, $rootService, $state, templateUrls, authObj ) {

    var header = this;

    function init() {

      header.currentLocation = {};
      header.templateUrls = templateUrls;
      header.currentLocale = $rootService.currentLocale;
      header.localeSelector = $rootService.localeSelector;
      header.cart = $rootService.getCart();
      header.auth = authObj;
    }


    //On translation change start
    $scope.$on('rootCurrentLocaleChange', function (event, new_locale, old_locale) {

      header.currentLocale = new_locale;
    });

    //On Location Url Change
    $scope.$on('rootCurrentLocationChange', function (event, new_location, old_location) {

      header.currentLocation = new_location;
    });

    //On Auth Change
    $scope.$on('rootAuthChange', function (event, new_auth, old_auth) {

      header.auth = new_auth;
    });

    //On Cart Change
    $scope.$on('rootCartChange', function (event, new_cart, old_cart) {

      header.cart = new_cart;
    });

    //Initialize
    init();

  }]);

})(angular);
