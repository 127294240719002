(function(angular){
  'use strict';

  //Load Module
  var ngCarbuApi = angular.module('ngCarbuApi');


  //Api Status
  ngCarbuApi.factory( '$carbuApiStatus', [ '$rootScope', '$carbuApi', function ($rootScope, $carbuApi) {

    var service = {

      reset: function() {
        return {
          success: false,
          statusCode: null,
          message: null
        };
      },
      setSuccess: function(response) {
        var status = service.reset();

        if (response.result) {
          response = response.result;
        }

        status.success = true;
        status.statusCode = response.status;
        status.code = response.status;

        if (angular.isDefined(response.messages) && angular.isArray(response.messages)) {
          status.message = response.messages[0].message;
        }
        else if (angular.isDefined(response.message) && angular.isObject(response.message)) {
          status.message = response.message.message;
        }
        else if (angular.isDefined(response.message) && angular.isString(response.message)) {
          status.message = response.message;
        }

        return status;
      },
      setError: function(response) {

        var status = service.reset();

        status.statusCode = response.status;
        status.code = response.status;

        if (angular.isDefined(response.data) && angular.isObject(response.data) && response.data.result) {
          response.data = response.data.result;
        }

        if ( angular.isDefined(response.data) && angular.isObject(response.data)) {

          status.code = response.data.status;
          status.message = response.data.message;
        }
        else {
          status.message = 'An error occured on the request.';
        }

        return status;
      },
      sendEvent: function(name, args) {
        $rootScope.$broadcast(name, args);
      }

    };

    return service;

  }]);

})(angular);
