(function(angular){
  'use strict';

  // Load module
  var pageOffers = angular.module('app.pages.offers');

  pageOffers.controller('offerRequestCtrl', ['$scope', '$log', '$timeout', '$rootService', '$carbuApiAuth', '$carbuApiOffers' , function ( $scope, $log, $timeout, $rootService, $carbuApiAuth, $carbuApiOffers ) {

    var request = this;

    function init() {

      request.search = $rootService.getSearch('params');
      request.userIsLoggedIn = $carbuApiAuth.isLoggedIn();

      request.alert =  {
        delay: 0.6,
        show: false,
        timeout: 0,
        type: 'alert',
        msg: ''
      };
    }

    /**
     * Hide Alert
     *
     */
    this.hideAlert = function() {

      var timeout = request.alert.show ? ((request.alert.delay * 1000) + 50) : 0;

      request.alert.show = false;
      request.alert.timeout = timeout;
    };


    /**
     * Show Alert
     *
     * @param msg
     * @param success
     */
    this.showAlert = function(msg, success) {

      $timeout(function () {
        request.alert.type = (success === true ? 'success' : 'alert');
        request.alert.msg = msg;
        request.alert.show = true;
      }, request.alert.timeout);
    };


    /**
     * Send a request for offer
     */
    this.send = function () {

      var submit_instance;

      request.sendForm.$setTouched();
      request.sendForm.$validate();

      if (request.sendForm.$valid) {

        request.hideAlert();

        var params = {
          areaCode: request.search.areaCode,
          productId: request.search.productId
        };

        params[request.search.by] = request.search.for;

        submit_instance = $carbuApiOffers.saveRequest(params, request.email);

        submit_instance.then(
          function (success) {

            request.showAlert(success.status.message, success.status.success);
            return success;
          },
          function (error) {

            request.showAlert(error.status.message);

            return error;
          }
        );
      }
    };


    //On Auth Change
    $scope.$on('rootAuthChange', function (event, new_auth, old_auth) {

      //Update form for user request
      request.userIsLoggedIn = new_auth.isLoggedIn;
    });

    //Initialize
    init();

  }]);

})(angular);
