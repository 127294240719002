(function(angular){
  'use strict';

  // Load module
  var pageAccount = angular.module('app.pages.account');

  pageAccount.controller('accountProfileCtrl', ['$scope', '$timeout', '$carbuApiAuth', 'userObj', function ( $scope, $timeout, $carbuApiAuth, userObj ) {

    var profile = this;

    function init() {

      profile.user = userObj;
      profile.fields = {
        firstName: profile.user.firstName,
        lastName: profile.user.lastName,
        phone: profile.user.phone,
        email: profile.user.email,
        vat: profile.user.vat,
        company: profile.user.company,
        address1: profile.user.address1,
        address2: profile.user.address2,
        postalCode: profile.user.postalCode,
        city: profile.user.city
      };

      profile.fieldsPassword = {
        current: '',
        new: ''
      };

      profile.alert =  {
        delay: 0.6,
        show: false,
        timeout: 0,
        type: 'alert',
        msg: ''
      };

      profile.alertPassword =  {
        delay: 0.6,
        show: false,
        timeout: 0,
        type: 'alert',
        msg: ''
      };
    }

    /**
     * Hide Alert Password
     *
     */
    this.hideAlertPassword = function() {

      var timeout = profile.alertPassword.show ? ((profile.alertPassword.delay * 1000) + 50) : 0;

      profile.alertPassword.show = false;
      profile.alertPassword.timeout = timeout;
    };


    /**
     * Show Alert Password
     *
     * @param msg
     * @param success
     */
    this.showAlertPassword = function(msg, success) {

      $timeout(function () {
        profile.alertPassword.type = (success === true ? 'success' : 'alert');
        profile.alertPassword.msg = msg;
        profile.alertPassword.show = true;
      }, profile.alertPassword.timeout);
    };

    /**
     * Hide Alert
     *
     */
    this.hideAlert = function() {

      var timeout = profile.alert.show ? ((profile.alert.delay * 1000) + 50) : 0;

      profile.alert.show = false;
      profile.alert.timeout = timeout;
    };


    /**
     * Show Alert
     *
     * @param msg
     * @param success
     */
    this.showAlert = function(msg, success) {

      $timeout(function () {
        profile.alert.type = (success === true ? 'success' : 'alert');
        profile.alert.msg = msg;
        profile.alert.show = true;
      }, profile.alert.timeout);
    };


    this.update = function() {

      var submit_instance;

      profile.userForm.$setTouched();
      profile.userForm.$validate();

      profile.hideAlert();

      if (profile.userForm.$valid) {

        submit_instance = $carbuApiAuth.updateUser(profile.fields);

        submit_instance.then(
          function (success) {

            profile.showAlert(success.status.message, success.status.success);
            return success;
          },
          function (error) {

            profile.showAlert(error.status.message);
            return error;
          }
        );
      }
    };


    this.savePassword = function() {
      profile.passwordForm.$setTouched();
      profile.passwordForm.$validate();

      profile.hideAlertPassword();

      if (profile.passwordForm.$valid) {

        $carbuApiAuth.updatePassword( profile.fieldsPassword.current, profile.fieldsPassword.new ).then(
          function (success) {

            profile.showAlertPassword(success.status.message, success.status.success);
            return success;
          },
          function (error) {

            profile.showAlertPassword(error.status.message);
            return error;
          }
        );
      }
    };

    //Initialize
    init();

  }]);

})(angular);
