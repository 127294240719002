(function(angular){
  'use strict';

  // Load module
  var pageOffers = angular.module('app.pages.offers');

  pageOffers.controller('offersSidebarCtrl', ['$scope', function ( $scope ) {

    var offersSidebar = this;

  }]);

})(angular);
