(function(angular){
  'use strict';

  // Load module
  var componentAuth = angular.module('app.components.auth');

  componentAuth.directive( 'authLogout', [ '$rootScope', '$carbuApiAuth', function ( $rootScope, $carbuApiAuth ) {
    return {
      restrict: "A",
      scope: {
        onSuccess: "&",
        onError: "&"
      },
      link: function ($scope, elem, attrs) {

        elem.on('click', function() {

          //Carbu logout
          $carbuApiAuth.logout().then(
            function (success) {
              $scope.onSuccess({$success: success});
              $rootScope.$broadcast('authLogout', success);
            },
            function (error) {
              $scope.onError({$error: error});
              $rootScope.$broadcast('authLogout', error);
            }
          );
        });

      }
    };
  }]);

})(angular);
