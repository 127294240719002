(function(angular){

  'use strict';

  // Load module
  var ngCarbuApi = angular.module('ngCarbuApi');


  ngCarbuApi.filter('ordersData', ['$filter', function( $filter ) {

    /**
     * Filter offers
     *
     * @param array offers
     * @return array
     */
    return function(orders) {

      var data = orders;

      /**
       * Parse Order
       *
       * @param order
       * @returns {{id: *, key: *, email, firstName: (*|Document.signupForm.firstName|Document.firstName), lastName: (*|Document.lastName), company: *, phone: (*|Document.phone), vat: (*|Document.vat), address1: (*|Document.address1), address2: (*|Document.address2), postalCode: *, city: *, country: *, comment: *}}
       * @private
       */
      function _parse( order ) {

        var user = order.user ? $filter('userData')(order.user) : {};
        var offer = {};

        if (order.offers && order.offers[0]) {

          offer = order.offers[0];
          offer.quantity = offer.quantity || order.quantity;

          offer = $filter('offersData')(order.offers[0]);
          delete order.offers;
        }

        order.offer = order.offer || offer;

        return angular.extend({} , order, user);
      }

      if (angular.isArray(orders)) {
        data = [];

        angular.forEach(orders, function(obj, key) {
          this.push(_parse(obj));
        }, data);

      }
      else if (angular.isObject(orders)) {
        data = _parse(orders);
      }

      return data;

    };

  }]);

})(angular);
