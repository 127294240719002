(function(angular){
  'use strict';

  var app = angular.module('app.root');


  //Root Service
  app.factory('$rootService', [
    'APP_CONFIG',
    'TEMPLATE_URLS',
    '$rootScope',
    '$window',
    '$location',
    '$injector',
    '$timeout',
    '$log',
    '$q',
    '$http',
    '$filter',
    'localStorageService',
    '$geolocation',
    '$carbuApi',
    '$carbuApiAuth',
    '$carbuApiService',
    '$posts',
    function (APP_CONFIG, TEMPLATE_URLS, $rootScope, $window, $location, $injector, $timeout, $log, $q, $http, $filter, localStorageService, $geolocation, $carbuApi, $carbuApiAuth, $carbuApiService, $posts ) {


    var current_location, search, template_urls, current_auth, current_cart, current_order;
    var service = {};


    /**
     * Build Template Urls
     * @param object
     * @returns {{}}
     * @private
     */
    function _build_template_urls( object ) {
      var template_object = {};

      angular.forEach(object, function (value, key) {

        if (angular.isObject(value)) {
          template_object[key] = _build_template_urls(value);
        }
        else {
          template_object[key] = APP_CONFIG.templateUrl + value;
        }
      });

      return template_object;
    }


    /**
     * Get Config
     *
     * @param property
     * @private
     */
    function _get_config( property ) {

      if (!angular.isString(property) || property === '') {
        return APP_CONFIG;
      }

      var arr = property.split('.');
      var obj = angular.copy(APP_CONFIG);

      while (arr.length && (obj = obj[arr.shift()])){}

      return obj;
    }


    /**
     * Set Auth
     *
     * @param auth
     * @private
     */
    function _set_auth(auth) {

      var old_auth = current_auth;

      current_auth = angular.copy(auth) || {};
      current_auth.data = {};

      //Filter auth object
      current_auth.data = $filter('userData')(auth.data);

      current_auth.isLoggedIn = $carbuApiAuth.isLoggedIn();
      current_auth.displayName = current_auth.isLoggedIn && (current_auth.data.firstName || current_auth.data.lastName) ? current_auth.data.firstName + ' ' + current_auth.data.lastName : null;

      //Rollbar configure person
      if ($injector.has('Rollbar')) {
        var Rollbar = $injector.get('Rollbar');

        Rollbar.configure({
          payload: {
            person: {
              id: current_auth.data ? current_auth.data.id : null,
              email: current_auth.data ?current_auth.data.email : null
            }
          }
        });
      }

      //Cut Display Name
      if (angular.isString(current_auth.displayName && current_auth.displayName.length >= 17 )) {
        current_auth.displayName = current_auth.displayName.substr(0, 17) + '...';
      }

      if (!angular.equals(old_auth, current_auth)) {
        //Add timeout to send event after run phase.
        $timeout(function () {
          $log.debug('rootAuthChange');
          $rootScope.$broadcast('rootAuthChange', current_auth, old_auth);
        }, 0, false);
      }

      return current_auth;
    }


    /**
     * Get Auth
     *
     * @returns {*|Promise}
     * @private
     */
    function _get_auth() {

      var defer = $q.defer();

      $carbuApiAuth.getAuth().then(
        function (success) {
          defer.resolve( _set_auth(success) );
        }, function (error) {
          defer.reject( _set_auth(error) );
        }
      );


      return defer.promise;
    }


    /**
     * Set Cart
     * @param item
     * @private
     */
    function _set_cart( item ) {

      var old_cart = current_cart;

      if (item && angular.isObject(item)) {

        current_cart = item;
        localStorageService.set('cart', current_cart);
      }
      else {
        current_cart = {};
        localStorageService.remove('cart');
      }

      if (!angular.equals(old_cart, current_cart)) {
        //Add timeout to send event after run phase.
        $timeout(function () {
          $log.debug('rootCartChange');
          $rootScope.$broadcast('rootCartChange', current_cart, old_cart);
        }, 0, false);
      }

      return current_cart;
    }


    /**
     * Get Cart
     * @private
     */
    function _get_cart() {

      current_cart = localStorageService.get('cart') || {};

      return current_cart;
    }


    /**
     * Set Order
     * @param order
     * @private
     */
    function _set_order( item, extend ) {

      var old_order = current_order;

      if (item) {

        current_order = extend === true ? angular.extend(current_order, item) : item;
        localStorageService.set('order', current_order, 'sessionStorage');
      }
      else {
        current_order = {};
        localStorageService.remove('order', 'sessionStorage');
      }

      if (!angular.equals(old_order, current_order)) {
        //Add timeout to send event after run phase.
        $timeout(function () {
          $log.debug('rootOrderChange');
          $rootScope.$broadcast('rootOrderChange', current_order, old_order);
        }, 0, false);
      }

      return current_order;
    }


    /**
     * Get Order
     * @private
     */
    function _get_order() {

      current_order = localStorageService.get('order', 'sessionStorage') || {};

      return current_order;
    }

    /**
     * Return locale
     * @param propery
     * @param value
     * @returns {*}
     * @private
     */
    function _get_locale( propery, value, return_key ) {

      var local_default = APP_CONFIG.locale.available[APP_CONFIG.locale.default];

      if (!propery) {
        return local_default;
      }

      if (value) {
        var locale;
        var locale_key;

        angular.forEach(APP_CONFIG.locale.available, function (_locale, _key) {

          if (_locale[propery] === value ) {
            locale = _locale;
            locale_key = _key;
          }
        });

        return (return_key === true) ? locale_key : locale;
      }

      return APP_CONFIG.locale.available[propery];
    }


    /**
     * Set the current locale
     * @private
     */
    function _set_current_locale( locale_id ) {

      var old_locale = service.currentLocale;

      locale_id = locale_id || APP_CONFIG.locale.default;
      var locale = _get_locale(locale_id);

      //Set document locale
      $window.document.documentElement.setAttribute('lang', locale_id);

      if (locale_id !== service.currentLocale.localeID) {

        service.currentLocale = angular.copy(locale);
        service.currentLocale.localeID = locale_id;
        service.currentLocale.display = locale.name;

        //Set api lang
        $carbuApi.setLang( locale.code );

        //Set local lang
        if ($injector.has('tmhDynamicLocale')) {
          var tmhDynamicLocale = $injector.get('tmhDynamicLocale');
          tmhDynamicLocale.set( locale.id );
        }

        //Set posts lang
        $posts.setLocale( locale.urlParam );

        $log.debug('Switch locale: from ' + old_locale.localeID + ', to ' + service.currentLocale.localeID);

        //Add timeout to send event after run phase.
        $timeout(function () {

          $log.debug('rootCurrentLocaleChange');

          $rootScope.$broadcast('rootCurrentLocaleChange', service.currentLocale, old_locale);
        }, 0, false);
      }
    }


    /**
     * Get the current location
     * @private
     */
    function _get_location_url() {

      return current_location || {};
    }

    /**
     * Set the current location
     * @private
     */
    function _set_location_url( location ) {

      var old_location = current_location;
      var url = $location.url();

      angular.forEach(APP_CONFIG.locale.available, function (value, key) {

        url = url.replace('/' + value.urlParam, '');
      });

      current_location = angular.extend({}, {
          'absUrl': $location.absUrl(),
          'localUrl': $location.url(),
          'url': url,
          'protocol': $location.protocol(),
          'port': $location.port(),
          'path': $location.path(),
          'search': $location.search(),
          'hash': $location.hash()
        },
        location
      );

      //Add timeout to send event after run phase.
      $timeout(function () {
        $rootScope.$broadcast('rootCurrentLocationChange', current_location, old_location);
      }, 0, false);
    }


    /**
     * Get location from storage
     *
     * @returns {Array}
     * @private
     */
    function _get_stored_locations () {

      var data = [];

      //Insert location from My Position
      if (service.geolocation.location) {

        data.push(service.geolocation.location);
      }

      //Insert search location
      if (search.location && !angular.equals(search.location, service.geolocation.location )) {
        data.push(search.location);
      }

      return data;
    }

    /**
     * Get locality
     * @param search
     * @param country
     * @returns {Promise}
     * @private
     */
    function _get_locations( value, params ) {

      //default params
      var default_params = {
        country: 'BE'
      };

      var defer = $q.defer();

      params = params || {};

      if (value && angular.isString(value) && value.length) {
        params.keyword = value;
        search = {};
      }

      if (params.lat === null || params.lng === null ) {
        defer.resolve(_get_stored_locations());
        return defer.promise;
      }

      $carbuApiService.getLocations( angular.extend(default_params, params), true ).then(
        function (result) {

          //If search is a position set location from geolocation on first result
          if ( params.lat && params.lng && result.length) {

            angular.extend(service.geolocation, { location: result[0]});
            localStorageService.set('geolocation', service.geolocation);
          }

          result = _get_stored_locations().concat(result);
          defer.resolve( result );
        },
        function(error) {
          defer.resolve(_get_stored_locations());
        }
      );

      return defer.promise;
    }

    /**
     * Get Search
     * @private
     */
    function _get_search ( key ) {

      if (key) {
        return search[key] || {};
      }

      return search || {};
    }

    /**
     * Set key value to search
     * @param key
     * @param value
     * @private
     */
    function _set_search ( key, value ) {

      var obj = {};

      obj[key] = value;

      angular.extend(search, obj);

      localStorageService.set('search', search);
    }

    /**
     * Get Location from search
     * @param location
     * @private
     */
    function _get_location() {

      //Return object empty if location not exist
      return _get_search('location') || {};
    }

    /**
     * Set Location to search
     * @param location
     * @private
     */
    function _set_location( location ) {

      //Not store formated description.
      delete location.formatedDescription;

      _set_search('location', location);
    }

    /**
     * Get Geolocation
     * @returns {Promise}
     * @private
     */
    function _get_geolocation() {

      var defer = $q.defer();

      $geolocation.getCurrentPosition(APP_CONFIG.geolocation || { timeout: 60000, maximumAge: 60000 }).then(
        function(position) {

          var coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          angular.extend(service.geolocation, coords);
          localStorageService.set('geolocation', service.geolocation);

          $log.debug('Update Position');
          defer.resolve( service.geolocation );
        },
        function(error) {
          $log.warn(error);
          defer.resolve( service.geolocation );
        }
      );

      return defer.promise;
    }


    /**
     * Get template urls by property string
     * @param property
     * @returns {*}
     * @private
     */
    function _get_template_urls( property ) {

      if (!angular.isString(property) || property === '') {
        return template_urls;
      }

      var arr = property.split('.');
      var obj = template_urls;

      while (arr.length && (obj = obj[arr.shift()])){}

      return obj;
    }


    /**
     * Initialize
     */
    function init() {

      //Current Locale
      service.currentLocale = angular.copy(_get_locale());
      service.currentLocale.localeID = APP_CONFIG.locale.default;
      service.currentLocale.display = APP_CONFIG.locale.available[APP_CONFIG.locale.default].name;

      //Locale Selector
      service.localeSelector = [];

      angular.forEach(APP_CONFIG.locale.available, function (value, key) {

        var locale = angular.copy(value);
        locale.localID = key;
        locale.display = value.name;

        this.push(locale);
      }, service.localeSelector);


      //geolocation
      service.geolocation = angular.extend({lat: null, lng: null}, localStorageService.get('geolocation') || {});
      search = angular.extend({}, localStorageService.get('search') || {});

      //News
      service.news = [];

      //Build Template Urls
      template_urls = _build_template_urls(TEMPLATE_URLS);

      //Get Properties
      $carbuApiService.getProperties().then(function (success) {
        service.news = success.news || [];
      }, function (error) {});
    }


    //Initialize
    init();


    /**
     * Public Api Exposed Services
     */
    return angular.extend(service, {
      getConfig:          _get_config,
      setAuth:            _set_auth,
      getAuth:            _get_auth,
      setCart:            _set_cart,
      getCart:            _get_cart,
      setOrder:           _set_order,
      getOrder:           _get_order,
      getTemplateUrls:    _get_template_urls,
      setCurrentLocale:   _set_current_locale,
      getLocale:          _get_locale,
      getLocationUrl:     _get_location_url,
      setLocationUrl:     _set_location_url,
      getLocations:       _get_locations,
      getStoredLocations: _get_stored_locations,
      getLocation:        _get_location,
      setLocation:        _set_location,
      getGeolocation:     _get_geolocation,
      getSearch:          _get_search,
      setSearch:          _set_search
    });

  }]);

})(angular);
