(function(angular){
  'use strict';

  var app = angular.module('app.root');

  app.controller('footerCtrl', ['$scope', '$rootService', 'templateUrls', function ( $scope, $rootService, templateUrls ) {

    var footer = this;

    function init() {

      footer.currentLocation = {};
      footer.templateUrls = templateUrls;
      footer.currentLocale = $rootService.currentLocale;
      footer.localeSelector = $rootService.localeSelector;
      footer.date = new Date();
    }


    //On translation change start
    $scope.$on('rootCurrentLocaleChange', function (event, current_locale) {

      footer.currentLocale = current_locale;
    });

    $scope.$on('rootCurrentLocationChange', function (event, new_location, old_location) {

      footer.currentLocation = new_location;
    });

    //Initialize
    init();

  }]);

})(angular);
