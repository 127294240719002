(function(angular){
  'use strict';

  // Load module
  var pageAccount = angular.module('app.pages.account');

  pageAccount.controller('accountOrdersCtrl', ['$scope', 'ordersObj', 'templateUrls', function ( $scope, ordersObj, templateUrls ) {

    var orders = this;

    function init() {

      orders.templateUrls = templateUrls;
      orders.items = ordersObj;
    }


    //Initialize
    init();

  }]);

})(angular);
