(function(angular){

  'use strict';

  // Create module
  var pageOffers = angular.module('app.pages.offers', [
    'ngCarbuApi',
    'ui.router',
    'rcDialog',
    'ngRateIt'
  ]);


  //This configures the routes and associates each route with a view and a controller
  pageOffers.config(['APP_CONFIG', '$stateProvider', function (APP_CONFIG, $stateProvider) {

    var state_offers =  {
      name: 'offers',
      parent: 'main',
      url: '/offers?areaCode&productId&by&for',
      params: {
        areaCode: {
          type: 'string',
          value: null
        },
        productId: {
          type: 'int',
          value: null
        },
        by: {
          type: 'string',
          value: null
        },
        for: {
          type: 'int',
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/offers/offers.tpl.html',
          controller: 'offersCtrl',
          controllerAs: 'offers'
        },
        '@offers': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/offers/main.tpl.html'
        },
        'sidebar@offers': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/offers/sidebar.tpl.html'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.offers');
        }],
        dialogsTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('dialogs.offer');
        }],
        offersObj: ['$q', '$location', '$state', '$stateParams', '$rootService', '$carbuApiOffers', function ($q, $location, $state, $stateParams, $rootService, $carbuApiOffers) {

          var deferred = $q.defer();
          var query_valid = ($stateParams.areaCode === null || $stateParams.productId === null || $stateParams.by === null || $stateParams.for === null) ? false : true;

          //Prevent offers query
          if (!query_valid) {

            deferred.reject({redirect: {to: 'home', options: {location: 'replace'}}});
            return deferred.promise;
          }

          var query = {
            areaCode: $stateParams.areaCode,
            productId: $stateParams.productId
          };

          switch ($stateParams.by) {
            case 'quantity':
              query.quantity = $stateParams.for;
              break;
            case 'budget':
              query.budget = $stateParams.for;
              break;
          }

          //Set Search
          var search_params = {};
          angular.forEach($stateParams, function (val, key) {

            if (val) {
              search_params[key] = val.toString();
            }
          });

          $rootService.setSearch('params', search_params);

          return $carbuApiOffers.getOffers(query);

        }],
        locationObj: [ '$rootService', function ($rootService) {

          return $rootService.getLocation();
        }],
        configObj: [ '$rootService', function ($rootService) {
          return $rootService.getConfig();
        }]
      }
    };

    $stateProvider.state(state_offers);

  }]);

})(angular);
