(function(angular){
  'use strict';


  // Load module
  var componentAuth = angular.module('app.components.auth');

  componentAuth.directive( 'auth', [ '$log', '$parse', function ($log, $parse) {

    return {
      restrict: "EA",
      transclude: true,
      controller: 'authCtrl',
      controllerAs: '$auth',
      scope : {
        authSubmit: '@',
        authDefault: "=?",
        authData: "=?",
        onSuccess: "&",
        onError: "&",
        onClose: "&"
      },
      link: function(scope, element, attrs, ctrl, transclude) {

        element.append(transclude(scope, function () {}));

        var tag_node = element.prop("tagName");

        if (tag_node === 'A' || tag_node === 'Button') {

          element.setDisabled = function ( state ) {

            if (state === true) {
              element.addClass('disabled');
              element.attr('disabled', '');
              element.attr('aria-disabled', '');
            }
            else {
              element.removeClass('disabled');
              element.attr('disabled', null);
              element.attr('aria-disabled', null);
            }
          };

          element.on('click', function() {

            if (!element.hasClass('disabled')) {
              element.setDisabled(true);
              scope.submit().then(
                function (response_success) {
                  element.setDisabled(false);
                },
                function (response_error) {
                  element.setDisabled(false);
                }
              );
            }
            else {
              return;
            }
          });

        }
      }
    };
  }]);

})(angular);
