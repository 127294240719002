(function(angular){
  'use strict';

  var ngConfig = angular.module('ngConfig');

  //Configuration Service
  ngConfig.factory('$ngConfig', [ '$log', '$q', '$http', function ($log, $q, $http ) {

    var config = {};
    var configUrl = 'config.json';
    var rev_el = document.querySelector('[ng-rev]');
    var rev_crc32 = '';

    /**
     *
     */
    function init() {

      if (CRC32 && rev_el && rev_el.src) {
        rev_crc32 = CRC32.str(rev_el.src).toString().slice(1);
      }
    }

    function _get_url_crc32 ( url ) {

      var pos = url.indexOf('.json');

      if (pos !== -1) {
        return url.substr(0, pos) + '.' + rev_crc32 + url.substr(pos);
      }

      return undefined;
    }

    function _set_config_url( url ) {

      if (angular.isString(url)) {
        configUrl =  url;
      }
    }


    function _get_config_url() {
      return configUrl;
    }


    /**
     * Get Config file
     * @private
     */
    function _get_config( url ) {

      url = url || configUrl;
      url = _get_url_crc32(url) || url;

      var configDeferred = config[url];

      if ( !configDeferred) {

        configDeferred = $q.defer();

        $http.get(url).then(
          function(success) {
            configDeferred.resolve(success);
          },
          function (error) {
            configDeferred.reject(error);
          }
        );

        config[url] = configDeferred;
      }

      return config[url].promise;
    }

    init();

    return {
      get: _get_config,
      setUrl: _set_config_url,
      getUrl: _get_config_url
    };

  }]);

})(angular);
