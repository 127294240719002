(function(angular){

  'use strict';

  // Load module
  var ngCarbuApi = angular.module('ngCarbuApi');


  ngCarbuApi.filter('newsAlert', ['$filter', '$state', function( $filter, $state ) {

    /**
     * Filter News
     *
     * @param array offers
     * @return array
     */
    return function(news) {

      var data = [];

      angular.forEach(news, function(message, key) {

        var page = message.page || 'home';

        if ( !page || $state.current.name === page ) {
          this.push({
            id: message.id || 0,
            timestamp: message.timestamp || 0,
            page: page,
            type: message.type,
            close: message.close || false,
            title: message.title,
            content: message.summary,
            url:  message.url
          });
        }

      }, data);

      return data;

    };

  }]);

})(angular);
