(function(angular){

  'use strict';

  // Create module
  var componentSearch = angular.module('app.components.search', [
    'ui.select',
    'ui.select.zf6',
    'ngLocationSearch',
    'ngCarbuApi'
  ]);


})(angular);
