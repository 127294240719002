(function (angular) {
  'use strict';

  var app = angular.module('app.root');

  //Set Angular Config
  app.config(['$compileProvider', '$logProvider', '$qProvider', '$sanitizeProvider', 'APP_CONFIG',
    function ($compileProvider, $logProvider, $qProvider, $sanitizeProvider, APP_CONFIG) {

      //Set debug Enable
      $logProvider.debugEnabled(APP_CONFIG.angular.enableDebug);
      $compileProvider.debugInfoEnabled(APP_CONFIG.angular.enableDebug);

      //Error Rejections
      $qProvider.errorOnUnhandledRejections(APP_CONFIG.angular.errorOnUnhandledRejections);

    }
  ]);


  //Animate Config
  app.config(['$animateProvider', function ($animateProvider) {
    $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
  }]);


  //Set Rollbar Config
  app.config(['RollbarProvider', 'APP_CONFIG', function(RollbarProvider, APP_CONFIG) {

    RollbarProvider.init({
      accessToken: APP_CONFIG.rollbar.accessToken,
      captureUncaught: APP_CONFIG.rollbar.captureUncaught,
      payload: angular.extend({}, { environment: APP_CONFIG.env }, (APP_CONFIG.rollbar.payload || {})),
      enableLogLevel: APP_CONFIG.rollbar.enableLogLevel
    });
  }]);


  //Set Localstorage
  app.config(['localStorageServiceProvider', 'APP_CONFIG', function (localStorageServiceProvider, APP_CONFIG) {

    localStorageServiceProvider
      .setPrefix(APP_CONFIG.appName || 'ngApp');
  }]);


  //Set Carbu Api Config
  app.config([ '$carbuApiProvider', 'APP_CONFIG', '$translateProvider', function( $carbuApiProvider, APP_CONFIG, $translateProvider ) {
    var locale =  APP_CONFIG.locale.available[APP_CONFIG.locale.default];

    $carbuApiProvider.setName(APP_CONFIG.appName || 'ngApp');
    $carbuApiProvider.setRest({
      url: APP_CONFIG.api.url,
      namespace: APP_CONFIG.api.namespace,
      routes: APP_CONFIG.api.routes
    });

    $carbuApiProvider.setSessionType( APP_CONFIG.api.sessionType );
    $carbuApiProvider.setAppId( APP_CONFIG.api.appId );
    $carbuApiProvider.setAccessToken( APP_CONFIG.api.accessToken );
    $carbuApiProvider.setLang( angular.extend({val: locale.code }, APP_CONFIG.api.language) );

    $carbuApiProvider.setMock( APP_CONFIG.api.mock || [] );
  }]);

  //Set Posts Config
  app.config([ '$postsProvider', 'APP_CONFIG', function( $postsProvider, APP_CONFIG ) {
    var locale =  APP_CONFIG.locale.available[APP_CONFIG.locale.default];

    $postsProvider.setLocale( locale.urlParam );
  }]);


  //Configure WebIcon
  app.config(['$webiconProvider', 'APP_CONFIG', function($webiconProvider, APP_CONFIG) {

    $webiconProvider.svgSet('fms-icons', APP_CONFIG.distUrl + 'images/webicon.svg');
  }]);

  //Configure Available Languages
  app.config(['$translateProvider', 'APP_CONFIG',
    function ($translateProvider, APP_CONFIG) {

      var available_lang_keys = {};
      var available_lang = [];

      angular.forEach(APP_CONFIG.locale.available, function (value, index) {
        available_lang_keys[index] = index;
        this.push(index);
      }, available_lang);

      available_lang_keys['*'] = APP_CONFIG.locale.default;

      $translateProvider
        .useLoader('asyncLangService')
        .registerAvailableLanguageKeys(available_lang, available_lang_keys)
        .useCookieStorage()
        .determinePreferredLanguage()
        .fallbackLanguage(APP_CONFIG.locale.default)
        .addInterpolation('$translateMessageFormatInterpolation')
        .useSanitizeValueStrategy('sceParameters');
  }]);


  //Configure Locale
  app.config(['tmhDynamicLocaleProvider', 'APP_CONFIG',
    function (tmhDynamicLocaleProvider, APP_CONFIG) {
      var locale =  APP_CONFIG.locale.available[APP_CONFIG.locale.default];

      tmhDynamicLocaleProvider.localeLocationPattern(APP_CONFIG.distUrl + 'scripts/angular-locale_{{locale}}.js');
      tmhDynamicLocaleProvider.defaultLocale(locale.id);
  }]);


  //Configure Iso Currencies
  app.config(['isoCurrenciesProvider', 'APP_CONFIG',
    function (isoCurrenciesProvider, APP_CONFIG) {

      isoCurrenciesProvider.setByCode(APP_CONFIG.currency.code);
      isoCurrenciesProvider.setText(APP_CONFIG.currency.text);
      isoCurrenciesProvider.setFraction(APP_CONFIG.currency.fraction);
      isoCurrenciesProvider.setFractionStrict(APP_CONFIG.currency.fractionStrict);
      isoCurrenciesProvider.setSymbol(APP_CONFIG.currency.symbol);
      isoCurrenciesProvider.setPosition(APP_CONFIG.currency.position);
      isoCurrenciesProvider.setDecimalSep(APP_CONFIG.currency.decimalSep);
      isoCurrenciesProvider.setThousandSep(APP_CONFIG.currency.thousandSep);

    }
  ]);


  //Configure Social Login
  app.config(['$socialLoginProvider', 'APP_CONFIG', function($socialLoginProvider, APP_CONFIG){

      if (APP_CONFIG.socialsAuth.google) {
        $socialLoginProvider.setGoogleKey(APP_CONFIG.socialsAuth.google.clientId);
      }

      if (APP_CONFIG.socialsAuth.facebook) {
        $socialLoginProvider.setFbKey({appId: APP_CONFIG.socialsAuth.facebook.appId, apiVersion: APP_CONFIG.socialsAuth.facebook.apiVersion});
      }

      if (APP_CONFIG.socialsAuth.linkedin) {
        $socialLoginProvider.setLinkedInKey(APP_CONFIG.socialsAuth.linkedin.clientId);
      }
    }
  ]);


  //Set UI-Router Config
  app.config([ '$locationProvider', '$stateProvider', '$urlRouterProvider', 'APP_CONFIG', 'isMobileProvider', '$uiViewScrollProvider', '$urlMatcherFactoryProvider',
    function( $locationProvider, $stateProvider, $urlRouterProvider, APP_CONFIG, isMobileProvider, $uiViewScrollProvider, $urlMatcherFactoryProvider ) {

      $uiViewScrollProvider.useAnchorScroll();

      //Set Html5
      $locationProvider.html5Mode(APP_CONFIG.angular.html5Mode).hashPrefix(APP_CONFIG.angular.hashPrefix);

      //Set if state not found to root state
      $urlRouterProvider.otherwise('/');

      //Set strict to false for prevent trailling slash.
      //$urlMatcherFactoryProvider.strictMode(false);
      $urlRouterProvider.rule(function($injector, $location) {

        var path = $location.path();
        var hasTrailingSlash = path[path.length-1] === '/';

        if(hasTrailingSlash) {

          //if last charcter is a slash, return the same url without the slash
          var newPath = path.substr(0, path.length - 1);

          return newPath;
        }

      });


      //Create Route States
      var regex_locale = '(?:';
      angular.forEach(APP_CONFIG.locale.available, function (value, index) {

        regex_locale += value.urlParam + '|';
      });
      regex_locale += 'null)';

      var main_state = {
        name: 'main',
        url: '/{locale:' + regex_locale + '}',
        abstract: true,
        params: {
          locale: {
            value: null
          }
        },
        views: {
          '@': {
            templateUrl: APP_CONFIG.templateUrl + 'global/main.tpl.html',
            controller: 'mainCtrl',
            controllerAs: 'main'
          },
          'header': {
            name: 'header',
            templateUrl: (isMobileProvider.phone) ? APP_CONFIG.templateUrl + 'global/header-mobile.tpl.html' : APP_CONFIG.templateUrl + 'global/header.tpl.html',
            controller: 'headerCtrl',
            controllerAs: 'header'
          },
          'footer': {
            name: 'footer',
            templateUrl: APP_CONFIG.templateUrl + 'global/footer.tpl.html',
            controller: 'footerCtrl',
            controllerAs: 'footer'
          }
        },
        resolve: {
          templateUrls: ['$rootService', function ($rootService) {
            return $rootService.getTemplateUrls();
          }],
          authObj: ['$rootService', function ($rootService) {

            //Resolve Auth success and error
            return $rootService.getAuth().then(
              function (auth) {
                return auth;
              },
              function (auth) {
                return auth;
              }
            );
          }]
        }
      };

      var not_found_state = {
        name: '404',
        parent: 'main',
        url: '/:path',
        params: {
          path: {
            type: 'string',
            value: null
          }
        },
        views: {
          '@': {
            templateUrl: APP_CONFIG.templateUrl + 'global/404.tpl.html',
            controller: 'mainCtrl',
            controllerAs: 'main'
          }
        }
      };

      $stateProvider.state(main_state);
      $stateProvider.state(not_found_state);
    }
  ]);


  //Add function to form directive for validate form on submit
  // example: $scope.myFormName.$setTouched();
  // example: $scope.myFormName.$validate();
  app.config([ '$provide', function($provide) {

    var form_directive = ['$delegate', function $formDecorator($delegate) {

      var fn = $delegate[0].controller.prototype;

      function control (form, callback) {

        if (form.$invalid) {

          angular.forEach(form, function(control, name) {
            // Excludes internal angular properties
            if (typeof name === 'string' && name.charAt(0) !== '$') {
              control[callback]();
            }
          });
        }
      }

      if (!('$setTouched' in fn)) {
        fn.$setTouched = function() {
          control(this, '$setTouched');
        };
      }

      if (!('$validate' in fn)) {
        fn.$validate = function() {
          control(this, '$validate');
        };
      }

      return $delegate;
    }];


    $provide.decorator('formDirective', form_directive);
    $provide.decorator('ngFormDirective', form_directive);
  }]);

}(angular));
