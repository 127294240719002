(function(angular){
  'use strict';

  // Load module
  var pageOrder = angular.module('app.pages.order');

  pageOrder.controller('orderCtrl', ['$scope', '$state', '$stateParams', 'templateUrls', 'searchObj', function ( $scope, $state, $stateParams, templateUrls, searchObj ) {

    var order = this;

    function init() {

      order.templateUrls = templateUrls;
      order.search = searchObj;
    }

    //Initialize
    init();

  }]);

})(angular);
