(function(angular){
  'use strict';

  // Load module
  var posts = angular.module('app.posts');


  /**
   * Element Chart Controller
   */
  posts.controller('postElementChartCtrl', ['$scope', '$window', '$log', '$locale', '$timeout', function ( $scope, $window, $log, $locale, $timeout) {

    var chart = this;

    /**
     * Private render callback delta
     */
    function render_delta () {

      if (chart.config.getChartObj && typeof chart.config.getChartObj === 'function') {

        var _chartObj = chart.config.getChartObj();
        var processedXData = _chartObj.series[0].processedXData;
        var processedYData = _chartObj.series[0].processedYData;

        var firstDate   = new Date((processedYData.length > 2 ? processedXData[1] : processedXData[0]));
        var firstValue  = processedYData.length > 2 ? processedYData[1] : processedYData[0];
        var lastValue   = processedYData[processedYData.length - 1];
        var lastDate    = new Date(processedXData[processedYData.length - 1]);

        var delta  = lastValue - firstValue;
        var deltaPct = parseInt( 10000 * delta / firstValue ) / 100;

        var deltaDisplay = '';
        var deltaPctDisplay = '';

        if(delta < 0){

          deltaDisplay += delta.toLocaleString('be-FR',{minimumFractionDigits:4});
          deltaPctDisplay += deltaPct.toLocaleString();
        } else {

          deltaDisplay += ' + ' + delta.toLocaleString('fr-FR',{minimumFractionDigits:4});
          deltaPctDisplay += ' + '+deltaPct.toLocaleString();
        }

        chart.delta = {
          display: deltaDisplay,
          displayPct: deltaPctDisplay,
          dateIn: firstDate.getDate()+'/'+(firstDate.getMonth()+1)+'/'+firstDate.getFullYear(),
          dateOut: lastDate.getDate()+'/'+(lastDate.getMonth()+1)+'/'+lastDate.getFullYear()
        };

        //Apply scope on update
        $timeout(function () {
          $scope.$apply();
        });
      }
    }


    /**
     * Initialize Controller
     */
    function init() {


      var yAxisTitle        = 'Prix (Euro/L)';
      var zoom_button_label   = ['1 sem', '1 mois', '6 mois', '1er janv', '1 an', 'Tout'];

      //Set config Highcharts
      if ($window.Highcharts) {

        $window.Highcharts.setOptions({
          lang: {
            decimalPoint:     $locale.NUMBER_FORMATS.DECIMAL_SEP || ',',
            thousandsSep:     $locale.NUMBER_FORMATS.GROUP_SEP || ' ',
            shortMonths:      $locale.DATETIME_FORMATS.SHORTMONTH || ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Déc'],
            shortWeekdays:    $locale.DATETIME_FORMATS.SHORTDAY || ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
            weekdays:         $locale.DATETIME_FORMATS.DAY || ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
            rangeSelectorFrom: 'au',
            rangeSelectorTo:   'Du'

          },
          global: {
            useUTC: false
          }

        });
      }

      chart.delta = {};
      chart.config = {
        chartType: 'stock',
        chart: {
          height: 350,
          events:{
            render:function(){
              render_delta();
            }
          }
        },
        tooltip: {
          xDateFormat: '%d/%m/%y'
        },
        rangeSelector: {
          inputEnabled: true,
          inputDateFormat: 	 '%d/%m/%Y',
          inputEditDateFormat: '%d/%m/%Y',
          inputDateParser: function (value){
            value = value.split('/');
            return Date.UTC(
              parseInt(value[2]),
              parseInt(value[1]) - 1,
              parseInt(value[0])
            );
          },
          enabled: true,
          xDateFormat: '%d/%m/%y',
          buttonSpacing: 10,
          buttonTheme: {
            width: 52
          },
          buttons: [
            {
              type: 'week',
              count: 1,
              text: zoom_button_label[0]
            }, {
              type: 'month',
              count: 1,
              text: zoom_button_label[1]
            },
            {
              type: 'month',
              count: 6,
              text: zoom_button_label[2]
            },
            {
              type: 'ytd',
              text: zoom_button_label[3]
            },
            {
              type: 'year',
              count: 1,
              text: zoom_button_label[4]

            }, {
              type: 'all',
              text: zoom_button_label[5]
            }
          ],
          selected:4
        },
        credits: {
          enabled: false
        },
        xAxis:{
          crosshair: true
        },
        yAxis : {
          title :{
            text: yAxisTitle
          },
          opposite: false
        },
        navigator: {
          xAxis: {
            type: 'datetime',
            labels: {
              format: '{value:%d/%m/%y}'    //ex- 01 Jan 2016
            },
            dateTimeLabelFormats: {
              day :'%d/%m/%y'
            }
          }
        },
        series: [
          {
            name: 'Prix',
            type:'line',
            dataGrouping: {
              dateTimeLabelFormats:{
                millisecond: [
                  '%A, %b %e, %H:%M:%S.%L', '%A, %b %e, %H:%M:%S.%L', '-%H:%M:%S.%L'
                ],
                second: ['%A, %b %e, %H:%M:%S', '%A, %b %e, %H:%M:%S', '-%H:%M:%S'],
                minute: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
                hour: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
                day: ['%A,%e %b %Y', '%A, %b %e', '-%A,%e %b %Y'],
                week: [' %A,%e %b %Y', '%A, %b %e', '-%A,%e %b %Y'],
                month: ['%B %Y', '%B', '-%B %Y'],
                year: ['%Y', '%Y', '-%Y']
              }
            }
          }
        ]
      };
    }


    this.getConfig = function( data ) {

      chart.data = [];

      angular.forEach(data, function(item, index){

        var p = [(index * 1000), parseFloat(item)];
        this.push(p);

      }, chart.data);

      chart.data.sort();
      chart.config.series[0].data = chart.data;

      return chart.config;
    };


    //Initialize
    init();

  }]);

})(angular);
