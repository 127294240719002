(function(angular){
  'use strict';

  var app = angular.module('app.root');

  /**
   * Get Element Size Width and Height width debounce
   *
   * This approach watches an elements height and width and assigns it to a variable
   * on the scope provided on your elements attribute
   * https://stackoverflow.com/questions/19048985/angularjs-better-way-to-watch-for-height-change
   */
  app.directive('elSize', ['$parse', '$timeout', '$window', function($parse, $timeout, $window) {

    return {
      link: function(scope, elem, attrs) {

        var fn = $parse(attrs.elSize);
        var debounce = scope.$eval(attrs.sizeDebounce) || 300;
        var name = attrs.sizeName || '';
        var viewport = scope.$eval(attrs.viewport) || false;

        var debounce_timeout = null;

        function fn_debounce (callback, interval, size) {
          $timeout.cancel(debounce_timeout);

          var args = size;

          debounce_timeout = $timeout(function () {
            callback(args);
          }, interval);
        }


        function fn_apply ( size ) {

          fn.assign(scope, size);

          scope.$emit('elSizeChanged', name, size, elem);
        }

        //Window change
        var throttledResize = function( is_delay ) {

          var height = viewport === true ? window.innerHeight : elem[0].offsetHeight;
          var width = viewport === true ? window.innerWidth : elem[0].offsetWidth;

          var size = { width: width, height: height };

          fn_debounce(fn_apply, (is_delay === false ? 0 : parseInt(debounce, 10)), size);
        };


        //Watch elem change
        scope.$watch(function() {

          return { width: elem[0].offsetWidth, height: elem[0].offsetHeight};
        }, function(new_size, old_size) {

          if ( angular.equals(new_size, old_size) ) {
            throttledResize();
          }
        }, true);

        //Bind resize event
        var windowEl = angular.element($window);


        windowEl.on('resize', throttledResize);

        var observer;

        //Observe Dom Element change
        if (typeof MutationObserver === 'function') {

          observer = new MutationObserver(function(mutations) {

            throttledResize();
          });

          observer.observe(elem[0], {
            childList: true,
            subtree: true,
            characterData: true,
            attributes: true
          });
        }


        //First time throttle resize
        throttledResize(false);

        scope.$on('$destroy', function () {
          windowEl.off('resize', throttledResize);

          if (observer) {
            observer.disconnect();
          }
        });
      }
    };
  }]);

})(angular);
