(function(angular){
  'use strict';

  // Load module
  var pageOrder = angular.module('app.pages.order');

  pageOrder.controller('orderErrorCtrl', ['$scope', '$filter', 'templateUrls', 'orderObj', function ( $scope, $filter, templateUrls, orderObj ) {

    var error = this;

    function init() {

      error.templateUrls = templateUrls;
      error.order = $filter('ordersData')(orderObj.data);
      error.status = orderObj.status;
    }

    //Initialize
    init();

  }]);

})(angular);
