(function(angular){
  'use strict';

  //Load Module
  var ngCarbuApi = angular.module('ngCarbuApi');

  ngCarbuApi.factory('$carbuApiService', ['$carbuApiResource', '$carbuApiStatus', '$carbuApi', '$q', '$filter', '$log', function ( $carbuApiResource, $carbuApiStatus, $carbuApi, $q, $filter, $log ) {

    var service = this;


    /**
     * Get Properties
     * @param params
     * @param cancel
     * @private
     */
    function _get_properties(params, cancel) {
      var request = null;

      //Cancel last request if param true
      if ((cancel === undefined || cancel === true) && service.propertiesRequest && typeof service.propertiesRequest.$cancelRequest === 'function') {
        service.propertiesRequest.$cancelRequest();
      }

      request = $q.defer();

      params = params || {};

      service.propertiesRequest = $carbuApiResource.properties.get(params,
        function (success, getResponseHeaders) {

          request.resolve( angular.isObject(success) ? success : {} );
        },
        function (error, getResponseHeaders) {

          request.resolve({});
        }
      );


      return request.promise;
    }


    /**
     * Get Location
     * @param params
     * @param cancel
     * @private
     */
    function _get_locations(params, cancel) {
      var request = null;

      //default
      var default_params = {
        minLevel: '5',
        maxLevel: '6'
      };

      //Cancel last request if param true
      if (cancel === true && service.locationsRequest && typeof service.locationsRequest.$cancelRequest === 'function') {
        service.locationsRequest.$cancelRequest();
      }

      request = $q.defer();

      angular.extend(params, default_params);

      if ( (params.keyword && params.keyword.length >= 3) || (params.lat && params.lng) )  {

        service.locationsRequest = $carbuApiResource.locations.query(params,
          function (success, getResponseHeaders) {

            request.resolve( angular.isArray(success) ? success : [] );
          },
          function (error, getResponseHeaders) {
            $log.warn(error);
            request.resolve([]);
          }
        );
      }
      else {
        request.resolve([]);
      }

      return request.promise;
    }


    /**
     * Get Products
     * @param params
     * @param cancel
     * @private
     */
    function _get_products(params, cancel) {
      var request = null;

      //Cancel last request if param true
      if (cancel === true && service.productsRequest && typeof service.productsRequest.$cancelRequest === 'function') {
        service.productsRequest.$cancelRequest();
      }

      request = $q.defer();

      service.productsRequest = $carbuApiResource.products.query(params,
        function (success, getResponseHeaders) {

          request.resolve( angular.isArray(success) ? success : []);
        },
        function (error, getResponseHeaders) {
          $log.warn(error);
          request.resolve([]);
        }
      );

      return request.promise;
    }


    /**
     * Get Articles
     *
     * @param params
     * @param cancel
     * @private
     */
    function _get_articles(params, cancel) {
      var request = null;

      //Cancel last request if param true
      if ((cancel === undefined || cancel === true) && service.articlesRequest && typeof service.articlesRequest.$cancelRequest === 'function') {
        service.articlesRequest.$cancelRequest();
      }

      request = $q.defer();

      service.articlesRequest = $carbuApiResource.articles.query(params,
        function (success, getResponseHeaders) {

          request.resolve( angular.isArray(success) ? success : []);
        },
        function (error, getResponseHeaders) {
          $log.warn(error);
          request.resolve([]);
        }
      );

      return request.promise;
    }


    /**
     * Get Article
     *
     * @param params
     * @private
     */
    function _get_article(params) {

      var request = $q.defer();
      var result = {
        status: $carbuApiStatus.reset(),
        data: null
      };

      if (params) {

        $carbuApiResource.articles.get(params,
          function (success, getResponseHeaders) {

            result.data = success.data ? success.data : success;

            result.status = $carbuApiStatus.setSuccess(success);
            request.resolve( result );
          },
          function (error, getResponseHeaders) {
            result.status = $carbuApiStatus.setError(error);
            $log.warn(error);
            request.reject(result);
          }
        );
      }
      else {
        request.reject( result );
      }

      return request.promise;
    }



    /**
     * Public Api Exposed Services
     */
    return {
      getProperties: _get_properties,
      getLocations: _get_locations,
      getProducts: _get_products,
      getArticles: _get_articles,
      getArticle: _get_article
    };
  }]);

})(angular);
