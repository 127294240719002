//Angular bootstrap application with modules.
(function (angular, window) {
  'use strict';

  //Bootstrap the angular application
  window.deferredBootstrapper.bootstrap({
    element: window.document.documentElement,
    module: 'app.root',
    injectorModules: ['ngConfig'],
    moduleResolves: [
      {
        module: 'app.root',
        resolve: {
          APP_CONFIG: ['$ngConfig', function ($ngConfig) {
            return $ngConfig.get();
          }]
        },
        onError: function (error) {
          alert('AngularJS Could not bootstrap, error: ' + error);
        }
      },
      {
        module: 'app.pages.home',
        resolve: {
          APP_CONFIG: ['$ngConfig', function ($ngConfig) {
            return $ngConfig.get();
          }]
        }
      },
      {
        module: 'app.pages.offers',
        resolve: {
          APP_CONFIG: ['$ngConfig', function ($ngConfig) {
            return $ngConfig.get();
          }]
        }
      },
      {
        module: 'app.pages.order',
        resolve: {
          APP_CONFIG: ['$ngConfig', function ($ngConfig) {
            return $ngConfig.get();
          }]
        }
      },
      {
        module: 'app.pages.account',
        resolve: {
          APP_CONFIG: ['$ngConfig', function ($ngConfig) {
            return $ngConfig.get();
          }]
        }
      },
      {
        module: 'app.posts',
        resolve: {
          APP_CONFIG: ['$ngConfig', function ($ngConfig) {
            return $ngConfig.get();
          }]
        }
      }
    ]
  });

  //Create Application with all dependencie modules
  window.ngApp = angular.module('app.root', [
    'tmh.dynamicLocale',
    'ngLocale',
    'ngAnimate',
    'ngSanitize',
    'ngCookies',
    'ngMessages',
    'ngCarbuApi',
    'LocalStorageModule',
    'pascalprecht.translate',
    'isoCurrencies',
    'ui.router',
    'ui.router.util',
    'ui.swiper',
    'rcRollbar',
    'webicon',
    'mm.foundation',
    'pageslide-directive',
    'ngGeolocation',
    'ismobile',
    'hl.sticky',
    '720kb.tooltips',
    'ngSocialLogin',
    'rcHttp',
    'oc.lazyLoad',
    'app.components.search',
    'app.components.auth',
    'app.pages.home',
    'app.pages.offers',
    'app.pages.order',
    'app.pages.account',
    'app.posts'
    ]
  );

}(angular, window));
