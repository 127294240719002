(function(angular){
  'use strict';

  // Load module
  var pageHome = angular.module('app.pages.home');

  pageHome.controller('homeCtrl', ['$scope', '$rootScope', '$log', '$rootService', '$timeout', '$window', 'isMobile', 'templateUrls', 'postObj',
    function ( $scope, $rootScope, $log, $rootService, $timeout, $window, isMobile, templateUrls, postObj) {

      var home = this;

      function init() {

        home.templateUrls = templateUrls;
        home.screenSize = {};
        home.bannerSize = {width: null, height: null};
        home.post = postObj;
      }

      /**
       * Disable auto size
        //On element size change
        var cleanUpElSizeChanged = $rootScope.$on('elSizeChanged', function (event, name, size, elem) {

          var elemSize = null;

          if (!isMobile.phone) {

            home.screenSize[name] = size;

            angular.forEach(home.screenSize, function (value, key) {

              if (key.length > 0) {
                elemSize += value.height;
              }
            });

            elemSize = 'calc(100vh - ' + elemSize + 'px)';

            $log.debug("Element Size Changed");
          }

          home.bannerSize.height = elemSize;
        });

         //Destroy event on controller destroy
         $scope.$on('$destroy', cleanUpElSizeChanged);
       */


      this.getPostElementTemplateUrl = function( name ) {

        return $rootService.getTemplateUrls('posts.elements.' + name);
      };

      //Initialize
      init();

  }]);

})(angular);
