(function(angular){

  'use strict';

  // Create module
  var componentAuth = angular.module('app.components.auth', [
    'ngCarbuApi',
    'rcDialog',
    'ui.mask',
    'ui.validate',
    'focus-if',
    'ng-slide-down'
  ]);


})(angular);
