(function(angular){
  'use strict';

  // Load module
  var pageAccount = angular.module('app.pages.account');

  pageAccount.controller('accountOffersRequestCtrl', [
    '$scope',
    '$log',
    'requestObj',
    'templateUrls',
    'offersTemplateUrls',
    'dialogsTemplateUrls',
    function ( $scope, $log, requestObj, templateUrls, offersTemplateUrls , dialogsTemplateUrls ) {

    var request = this;

    function init() {

      angular.extend(request, requestObj);

      $scope.offers = {
        items: requestObj.offers,
        templateUrls: offersTemplateUrls,
        dialogsTemplateUrls: dialogsTemplateUrls.offer
      };
    }

    //Initialize
    init();

  }]);

})(angular);
