(function(angular){
  'use strict';

  // Load module
  var pageAccount = angular.module('app.pages.account');

  pageAccount.controller('accountOffersRequestsCtrl', ['$scope', 'requestsObj', 'templateUrls', function ( $scope, requestsObj, templateUrls ) {

    var requests = this;

    function init() {

      requests.templateUrls = templateUrls;
      requests.items = requestsObj;
    }


    //Initialize
    init();

  }]);

})(angular);
