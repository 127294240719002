(function(angular){

  'use strict';

  // Create module
  var pageAccount = angular.module('app.pages.account', [
    'ngCarbuApi',
    'ui.router',
    'app.components.auth',
    'ismobile',
    'rcDialog'
  ]);


  //This configures the routes and associates each route with a view and a controller
  pageAccount.config(['APP_CONFIG', '$stateProvider', function (APP_CONFIG, $stateProvider) {

    // Abstract account
    var state_account = {
      name: 'account',
      parent: 'main',
      url: '/account',
      abstract: true,
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/account.tpl.html',
        },
        'sidebar@account': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/sidebar.tpl.html'
        }
      }
    };

    // Account Dashboard
    var state_account_dashboard = {
      name: 'account.dashboard',
      url: '/dashboard',
      protected: true,
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/dashboard/main.tpl.html',
          controller: 'accountDashboardCtrl',
          controllerAs: 'dashboard'
        }
      },
      resolve: {
        offersTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.account.offers');
        }],
        ordersTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.account.orders');
        }],
        requestsObj: ['$q', '$carbuApiOffers', function ($q, $carbuApiOffers) {

          return $carbuApiOffers.getRequests();
        }],
        ordersObj: ['$q', '$carbuApiOrders', function ($q, $carbuApiOrders) {

          return $carbuApiOrders.getOrders();
        }]
      }
    };


    // Account Offers
    var state_account_offers = {
      name: 'account.offers',
      url: '/offers',
      redirectTo: 'account.offers.requests',
      templateUrl: APP_CONFIG.templateUrl + 'pages/account/offers/main.tpl.html'
    };

    var state_account_offers_requests = {
      name: 'account.offers.requests',
      url: '',
      protected: true,
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/offers/requests.tpl.html',
          controller: 'accountOffersRequestsCtrl',
          controllerAs: 'requests'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.account.offers');
        }],
        requestsObj: ['$q', '$carbuApiOffers', function ($q, $carbuApiOffers) {

          return $carbuApiOffers.getRequests();
        }]
      }
    };

    var state_account_offers_request = {
      name: 'account.offers.request',
      url: '/:key',
      protected: true,
      params: {
        key: {
          type: 'string',
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/offers/request.tpl.html',
          controller: 'accountOffersRequestCtrl',
          controllerAs: 'request'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.account.offers');
        }],
        offersTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.offers');
        }],
        dialogsTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('dialogs');
        }],
        requestObj: ['$q', '$carbuApiOffers', '$stateParams', function ($q, $carbuApiOffers, $stateParams) {

          var deferred = $q.defer();

          //Prevent key null
          if (!$stateParams.key) {

            //Redirect parent state
            deferred.reject({redirect: {to: 'account.offers', options: {location: 'replace'}}});
            return deferred.promise;
          }

          $carbuApiOffers.getRequest($stateParams.key).then(
            function (success) {

              deferred.resolve(success.data);
            },
            function (error) {

              deferred.reject({redirect: {to: 'account.offers', options: {location: 'replace'}}});
            }
          );

          //Resolve success and error.
          return deferred.promise;
        }]
      }
    };


    // Account Offers
    var state_account_orders = {
      name: 'account.orders',
      url: '/orders',
      redirectTo: 'account.orders.list',
      templateUrl: APP_CONFIG.templateUrl + 'pages/account/orders/main.tpl.html'
    };

    var state_account_orders_list = {
      name: 'account.orders.list',
      url: '',
      protected: true,
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/orders/list.tpl.html',
          controller: 'accountOrdersCtrl',
          controllerAs: 'orders'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.account.orders');
        }],
        ordersObj: ['$q', '$carbuApiOrders', function ($q, $carbuApiOrders) {

          return $carbuApiOrders.getOrders();
        }]
      }
    };

    var state_account_orders_details = {
      name: 'account.orders.details',
      url: '/:key',
      protected: true,
      params: {
        key: {
          type: 'string',
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/orders/details.tpl.html',
          controller: 'accountOrderCtrl',
          controllerAs: 'order'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.account.orders');
        }],
        globalTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('global');
        }],
        orderObj: ['$q', '$carbuApiOrders', '$stateParams', function ($q, $carbuApiOrders, $stateParams) {

          var deferred = $q.defer();

          //Prevent key null
          if (!$stateParams.key) {

            //Redirect parent state
            deferred.reject({redirect: {to: 'account.orders', options: {location: 'replace'}}});
            return deferred.promise;
          }

          $carbuApiOrders.getOrder($stateParams.key).then(
            function (success) {

              deferred.resolve(success.data);
            },
            function (error) {

              deferred.reject({redirect: {to: 'account.orders', options: {location: 'replace'}}});
            }
          );

          //Resolve success and error.
          return deferred.promise;
        }]
      }
    };


    // Account Profile
    var state_account_profile = {
      name: 'account.profile',
      url: '/profile',
      protected: true,
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/profile/main.tpl.html',
          controller: 'accountProfileCtrl',
          controllerAs: 'profile'
        }
      },
      resolve: {
        userObj: ['$q', '$carbuApiAuth', function ($q, $carbuApiAuth) {

          var deferred = $q.defer();

          //Resolve success and error.
          $carbuApiAuth.getUser().then(
            function (success) {

              deferred.resolve(success.data);
            },
            function (error) {

              deferred.reject(error);
            }
          );

          return deferred.promise;
        }]
      }
    };


    // Abstract auth
    var state_auth = {
      name: 'auth',
      parent: 'account',
      url: '',
      abstract: true,
      template: '<ui-view/>'
    };

    // SignIn
    var state_auth_signin = {
      name: 'auth.signin',
      url: '/signin',
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/auth/signin.tpl.html'
        }
      }
    };

    // SignUp
    var state_auth_signup = {
      name: 'auth.signup',
      url: '/signup',
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/auth/signup.tpl.html',
          controllerAs: 'auth',
          controller: ['templateUrls',  function (templateUrls) {
            var auth = this;
            auth.templateUrls = templateUrls;
          }]
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('global');
        }]
      }
    };

    // Confirm Email
    var state_auth_confirm = {
      name: 'auth.confirm',
      url: '/confirm?email&key',
      params: {
        email: {
          type: 'string',
          value: null
        },
        key: {
          type: 'string',
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/auth/confirm.tpl.html',
          controllerAs: 'auth',
          controller: ['paramsObj',  function (paramsObj) {
            var auth = this;
            auth.params = paramsObj;
          }]
        }
      },
      resolve: {
        paramsObj: ['$q', '$state', '$stateParams', function ($q, $state, $stateParams) {

          var deferred = $q.defer();
          var query_valid = ($stateParams.email === null || $stateParams.key === null) ? false : true;

          //Prevent reset password
          if (!query_valid ) {

            deferred.reject({redirect: {to: 'home', options: {location: 'replace'}}});
            return deferred.promise;
          }

          deferred.resolve($stateParams);

          return deferred.promise;
        }]
      }
    };

    // Lost Password
    var state_auth_forgotten_password = {
      name: 'auth.forgottenPassword',
      url: '/lostpassword',
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/auth/forgotten-password.tpl.html'
        }
      }
    };

    // Reset Password
    var state_auth_reset_password = {
      name: 'auth.resetPassword',
      url: '/resetpassword?key',
      params: {
        key: {
          type: 'string',
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/account/auth/reset-password.tpl.html',
          controllerAs: 'auth',
          controller: [ 'paramsObj',  function (paramsObj) {
            var auth = this;
            auth.params = paramsObj;
          }]
        }
      },
      resolve: {
        paramsObj: ['$q', '$state', '$stateParams', function ($q, $state, $stateParams) {

          var deferred = $q.defer();
          var query_valid = ($stateParams.key === null) ? false : true;

          //Prevent reset password
          if (!query_valid) {

            deferred.reject({redirect: {to: 'home', options: {location: 'replace'}}});
            return deferred.promise;
          }

          deferred.resolve($stateParams);

          return deferred.promise;
        }]
      }
    };


    //Account
    $stateProvider.state(state_account);
    $stateProvider.state(state_account_dashboard);

    $stateProvider.state(state_account_offers);
    $stateProvider.state(state_account_offers_requests);
    $stateProvider.state(state_account_offers_request);

    $stateProvider.state(state_account_orders);
    $stateProvider.state(state_account_orders_list);
    $stateProvider.state(state_account_orders_details);

    $stateProvider.state(state_account_profile);

    //Account Authentication
    $stateProvider.state(state_auth);
    $stateProvider.state(state_auth_signin);
    $stateProvider.state(state_auth_signup);
    $stateProvider.state(state_auth_confirm);
    $stateProvider.state(state_auth_forgotten_password);
    $stateProvider.state(state_auth_reset_password);

}]);

})(angular);
