(function (angular) {
  'use strict';


  var app = angular.module('app.root');


  //Run Angular Application
  app.run([
    '$rootScope',
    '$window',
    '$location',
    '$injector',
    '$log',
    'APP_CONFIG',
    '$rootService',
    '$translate',
    '$transitions',
    '$state',
    '$stateParams',
    '$timeout',
    'isMobile',
    '$stateAuth',
    '$stateDialog',
    function ( $rootScope, $window, $location, $injector, $log, APP_CONFIG, $rootService, $translate, $transitions, $state, $stateParams, $timeout, isMobile, $stateAuth, $stateDialog ) {


      $rootScope.screenSize = {
        header: {width: null, height: null},
        footer: {width: null, height: null}
      };

      $log.info('Application: ' + APP_CONFIG.appName);
      $log.info('Environment: ' + APP_CONFIG.env);
      $log.info('Version:     ' + APP_CONFIG.version);
      $log.debug('Angular:     ' + angular.version.full);

      //Timeout use for angular ready
      $timeout(function () {
        //Cloak animation
        var cloak_elements = document.querySelectorAll('.ng-cloak-animation');
        [].forEach.call(cloak_elements, function (elem, i) {
          angular.element(elem).removeClass('ng-cloak-animation');
        });

        //Initialize Script objectFitImages if exist.
        if (angular.isDefined($window.objectFitImages)) {
          objectFitImages('img.fit');

          $log.debug('objectFitImages Initialized');
        }

        //Initialize script AOS if exist.
        if (angular.isDefined($window.AOS)) {

          AOS.init();

          $log.debug('AOS Initialized');
        }
      });

      //Map Error Handler ui-router
      $state.defaultErrorHandler(function(error) {

        //Workaround log error for superseded by a different transition type: 2
        switch(error.type) {
          case 2:   //Transistion superseded
            $log.debug(error);
            break;
          case 6:   //Transistion Error (promise reject)
            $log.debug(error);

            //Redirect to sate on error promise rejected if redirect is set has object.
            if (angular.isObject(error.detail.redirect)) {

              var redirect = angular.extend({
                to: null,
                toParams: {},
                options: {}
              }, error.detail.redirect);

              $state.go(redirect.to, redirect.toParams, redirect.options);
            }
            break;
          default:
            $log.error(error);
        }
      });

      //On sate Before Transition
      $transitions.onBefore({}, function(transition) {

        var stateService = transition.router.stateService;
        var to_state = transition.to();
        var from_state = transition.from();

        return $stateAuth.get(to_state, from_state).then(
          function (new_state) {
            //Open Dialog authentication for not mobile
            if (new_state.name.indexOf('auth') === 0 && !isMobile.phone) {
              return stateService.target($stateDialog.open(new_state, from_state, 'home', null, {params: transition.params()}));
            }

            return;
          },
          function (new_state) {
            return stateService.target(new_state);
          }
        );
      });

      //On state Start
      $transitions.onStart({}, function (transition) {

        var to_params = transition.params('to');
        var from_params = transition.params('from');
        var locale_id = $rootService.getLocale('urlParam', to_params.locale, true);

        if (APP_CONFIG.locale.reloadOnChange === true && from_params.locale && to_params.locale !== from_params.locale) {
          //Reload full page on switch language
          $window.location.reload();
        }
        else {
          //Dynamic set current language without reload full page.
          $rootService.setCurrentLocale(locale_id);
        }

      });

      //On state Success
      $transitions.onSuccess({}, function (transition) {

        var current_locale = $translate.use();
        var toParams = transition.params('to');

        var locale =  $rootService.getLocale(current_locale);

        //Set language by state param
        if (!locale || locale.urlParam !== toParams.locale) {
          var locale_key = $rootService.getLocale('urlParam',  toParams.locale, true);

          $translate.use(locale_key || current_locale);
        }

        $rootService.setLocationUrl({state: $state.current});
      });

      //On state Error
      $transitions.onError({}, function(transition) {

        $log.debug("Route Transition Error state: ");
        $log.debug(transition.error());
      });

      //On location change success
      $rootScope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {

        $rootService.setLocationUrl({state: $state.current});
      });

      //On translation change start
      $rootScope.$on('$translateChangeStart', function (event, translate) {

        var locale =  $rootService.getLocale(translate.language);

        //Go state on dynamic language change
        if (!$stateParams.locale) {
          $state.go($state.current.name, {locale: locale.urlParam}, {reload: true});
        }
      });

      //On translation change success
      $rootScope.$on('$translateChangeSuccess', function (event, translate) {

        $rootService.setCurrentLocale(translate.language);
        $rootService.setLocationUrl({state: $state.current});
      });


      //On auth change get
      $rootScope.$on('$carbuApi:auth-get-status', function (event, auth) {

        $rootService.setAuth(auth);
      });

      //On auth change logout
      $rootScope.$on('$carbuApi:auth-logout-status', function (event, auth) {

        $rootService.setAuth(auth);
        $state.reload();
      });
      
    }
  ]);


}(angular));
