(function(angular){
  'use strict';

  //Load Module
  var ngCarbuApi = angular.module('ngCarbuApi');

  ngCarbuApi.factory('$carbuApiOrders', [
    '$injector',
    '$carbuApiResource',
    '$carbuApiStatus',
    '$carbuApiConst',
    '$filter',
    '$q',
    '$log',
    function ( $injector, $carbuApiResource, $carbuApiStatus, $carbuApiConst, $filter, $q, $log ) {

    var service = this;

    service.request = {};


    /**
     * Save Order
     * @param order
     * @private
     */
    function _save_order( order ) {

      //Inject Authentification
      var $carbuApiAuth = $injector.get('$carbuApiAuth');

      var deferred = $q.defer();

      var params = {
        email: order.email,
        firstName: order.firstName,
        lastName: order.lastName,
        phone: order.phone,
        company: order.company,
        vat: order.vat,
        address1: order.address1,
        address2: order.address2,
        postalCode: order.postalCode,
        paymentMethod: order.paymentMethod,
        comment: order.comment,
        password: order.password,
        registerNews: order.registerNews,
        userId: $carbuApiAuth.getUserId() || undefined,
        offerKey: order.offerKey,
        userKey: $carbuApiAuth.getUserKey() || undefined
      };


      $carbuApiResource.orders.save(params).$promise.then(
        function (success) {

          deferred.resolve({
            status: $carbuApiStatus.setSuccess(success),
            data: (success.data ? success.data : success)
          });
        },
        function (error) {
          $log.warn(error);
          deferred.reject({
            status: $carbuApiStatus.setError(error),
            data: null
          });
        }
      );


      return deferred.promise;
    }


    /**
     * Get Orders
     *
     * @param params
     * @param cancel
     * @returns {*}
     * @private
     */
    function _get_orders(params, cancel) {

      var deferred = null;
      var result = {
        status: $carbuApiStatus.reset(),
        data: null
      };

      //Inject Authentification
      var $carbuApiAuth = $injector.get('$carbuApiAuth');

      params = params || {};
      params = angular.extend({
        //status: $carbuApiConst.status.pending,
        userKey: $carbuApiAuth.getUserKey(),
        userId: $carbuApiAuth.getUserId()
      }, params);

      if (angular.isString(params.userKey)) {

        //Cancel last request if param true
        if ((cancel === undefined || cancel === true) && service.request.orders && typeof service.request.orders.$cancelRequest === 'function') {
          service.request.orders.$cancelRequest();
        }

        deferred = $q.defer();

        var $http = $injector.get('$http');

        //service.request.orders = $carbuApiResource.orders.query(params,
        //Mock data
        $http.get('/mock-data/orders.json').then(
          function (success, getResponseHeaders) {

            //TODO remove this get always array only for mock
            success = success.data.data ? success.data.data : success.data;

            deferred.resolve( angular.isArray(success) ? $filter('ordersData')(success) : [] );
          },
          function (error, getResponseHeaders) {
            $log.warn(error);
            deferred.resolve([]);
          }
        );
      }
      else {
        deferred.reject( result );
      }

      return deferred.promise;
    }

    /**
     * Get Order
     * @param key
     * @private
     */
    function _get_order( key ) {

      var deferred = $q.defer();
      var result = {
        status: $carbuApiStatus.reset(),
        data: null
      };

      var params = {
        key: key
      };

      //Inject Authentification
      var $carbuApiAuth = $injector.get('$carbuApiAuth');

      if ($carbuApiAuth.isLoggedIn()) {
        params.userKey = $carbuApiAuth.getUserKey();
      }

      if (angular.isString(params.key) && params.key.length > 0) {

        $carbuApiResource.orders.get(params).$promise.then(
          function (success) {

            result.data = $filter('ordersData')(success.data ? success.data : success);

            result.status = $carbuApiStatus.setSuccess(success);
            deferred.resolve( result );
          },
          function (error) {
            $log.warn(error);
            deferred.reject({
              status: $carbuApiStatus.setError(error),
              data: null
            });
          }
        );
      }
      else {
        deferred.reject( result );
      }

      return deferred.promise;
    }


    /**
     * Public Api Exposed Services
     */
    return {
      saveOrder: _save_order,
      getOrders: _get_orders,
      getOrder: _get_order
    };
  }]);

})(angular);
