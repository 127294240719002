(function(angular){
  'use strict';


  var app = angular.module('app.root');

  app.factory('$stateDialog', [
    '$stateParams',
    '$state',
    '$rootService',
    'rcDialog',
    function ($stateParams, $state, $rootService, rcDialog ) {

    return {
      open: function (to_state, from_state, default_state, dialog, data) {

        var new_state = from_state;

        //Convert state name string to a state
        to_state = angular.isString(to_state) ? $state.get(to_state) : to_state;
        from_state = angular.isString(from_state) ? $state.get(from_state) : from_state;
        default_state = angular.isString(default_state) ? $state.get(default_state) : default_state;

        if (to_state && to_state.name) {

          //Prevent state for open dialog
          var is_invalid_from_state = (
            from_state.protected ||
            !angular.isString(from_state.name) ||
            !from_state.name.length ||
            from_state.name.indexOf('auth') === 0 ||
            from_state.name.indexOf('account') === 0
          );

          dialog = angular.extend({
            theme: 'foundation',
            size: 'tiny',
            backdrop: true,
            clickClose: true,
            escClose: true,
            templateUrl: $rootService.getTemplateUrls('dialogs.' + to_state.name)
          }, dialog);

          data = angular.extend({params: $stateParams, templateUrls: $rootService.getTemplateUrls() }, data);

          rcDialog.open(dialog, data);

          if (is_invalid_from_state) {
            new_state = default_state;
          }
        }

        return new_state;
      }
    };
  }]);

})(angular);
