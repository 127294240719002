(function(angular){
  'use strict';

  // Load module
  var componentSearch = angular.module('app.components.search');

  componentSearch.controller('searchCtrl', [
    '$scope',
    '$rootService',
    '$carbuApiService',
    '$timeout',
    '$filter',
    function ( $scope, $rootService, $carbuApiService, $timeout, $filter ) {

      var search = this;
      var config = $rootService.getConfig();
      var last_search = $rootService.getSearch('params');

      function init() {

        search.change = false;
        search.geolocation = {};

        search.productCat   = search.productCat || config.search.default.productCat;
        search.byOptions = search.byOptions || config.search.byOptions;

        search.areaCode = search.areaCode || last_search.areaCode;
        search.by = search.by || last_search.by;
        search.for = search.for || last_search.for;
        search.productId = search.productId || parseInt(last_search.productId, 10);

        //Resolve locations
        search.applyLocations();

        //Set default
        search.applyBy();

        //Preview
        var preview_location = $filter('filter')(search.locations, {areaCode: search.areaCode});
        var preview_for_options = $filter('filter')(search.forOptions, {id: search.for});

        search.preview = {
          location: (angular.isArray(preview_location[0]) && preview_location.length) ? preview_location[0].name : '',
          for: (angular.isArray(preview_for_options[0]) && preview_for_options.length) ? preview_for_options[0].name : '',
          by: search.byOptions[search.by]
        };

        //Resolve product options
        $carbuApiService.getProducts({category: search.productCat}).then(function (products) {

          search.productOptions = products;

          var _selected_products = ($filter('filter')(products, {id: search.productId}));
          var _product = (_selected_products[0]) ? _selected_products[0] : products[0];

          search.productId = _product.id;
          search.preview.product = _product.name;
        });

        //Resolve location with geolocation
        //search.findGeolocation();
      }

      /**
       * Refresh the search autocomplete
       * @param str
       */
      this.getAutocompleteLocation = function ( str ) {
        $rootService.getLocations( str ).then(
          function( result ) {
            search.locations = result;
          }
        );
      };


      /**
       * Apply the by input
       * @param by
       */
      this.applyBy = function ( by ) {

        if (by && by !== search.by) {
          search.change=true;
        }

        search.by          = by || search.by || config.search.default.by;
        search.forOptions  = config.search.forOptions[search.by] ||  {"id": "1000", "name": "1000"};

        //Set default for
        if (by || !search.for) {
          search.for = config.search.default[search.by];
        }

      };


      /**
       * Apply the locations for the location input
       * @param locations
       */
      this.applyLocations = function ( locations ) {

        search.locations = angular.isArray(locations)  ? locations : $rootService.getStoredLocations();

        if (locations && search.locations.length > 0) {
          search.areaCode = search.locations[0].areaCode;
        }
      };


      /**
       * Update Location
       * @param location
       */
      this.updateLocation = function ( location ) {

        $rootService.setLocation(location);
      };


      /**
       * Find Geolocation
       */
      this.findGeolocation = function () {

        if (search.geolocation.inProgress) {
          return;
        }

        search.geolocation.inProgress = true;

        $rootService.getGeolocation().then(function ( geolocation ) {
          $rootService.getLocations( '', {lat: geolocation.lat, lng: geolocation.lng} ).then(
            function( result ) {
              search.geolocation.inProgress = false;
              search.applyLocations(result);
            }
          );
        });
      };


      $scope.$on('ngLocationSearchChangeError', function (event, type, name, data) {

        if (type === 'form') {
          $scope[name].$setTouched();
          $scope[name].$validate();
        }
      });


      $scope.$on('ngLocationSearchChangeStart', function (event, new_search, old_search) {

        search.change = false;
      });

      $scope.$on('ngLocationSearchChangeSuccess', function (event, new_search, old_search) {

        //Workaround search change from url always replace by string. Change to numeric
        search.productId = parseInt(search.productId, 10);
      });


      //Initialize
      init();

    }
  ]);

})(angular);
