(function(angular){

  'use strict';

  // Load module
  var posts = angular.module('app.posts');


  posts.filter('postsArticle', ['$filter', function( $filter ) {

    /**
     * Filter offers
     *
     * @param array offers
     * @return array
     */
    return function(posts) {

      var data = posts;

      /**
       * Parse Post
       *
       * @param post
       * @returns {{id: *, key: *, email, firstName: (*|Document.signupForm.firstName|Document.firstName), lastName: (*|Document.lastName), company: *, phone: (*|Document.phone), vat: (*|Document.vat), address1: (*|Document.address1), address2: (*|Document.address2), postalCode: *, city: *, country: *, comment: *}}
       * @private
       */
      function _parse( post ) {

        return {
          id: post.id,
          slug: post.slug,
          title: post.title || '',
          media: post.media,
          categories: post.categories || [],
          content: post.content || ''
        };
      }

      if (angular.isArray(posts)) {
        data = [];

        angular.forEach(orders, function(obj, key) {
          this.push(_parse(obj));
        }, data);

      }
      else if (angular.isObject(posts)) {
        data = _parse(posts);
      }

      return data;

    };

  }]);

})(angular);
