(function(angular){
  'use strict';

  // Load module
  var pageOrder = angular.module('app.pages.order');

  pageOrder.controller('orderConfirmCtrl', ['$scope', '$filter', '$rootService', 'templateUrls', 'globalTemplateUrls', 'orderObj', function ( $scope, $filter, $rootService, templateUrls, globalTemplateUrls, orderObj ) {

    var confirm = this;

    function init() {

      confirm.templateUrls = templateUrls;
      confirm.globalTemplateUrls = globalTemplateUrls;

      confirm.contactSupplierTplUrl = $rootService.getTemplateUrls('dialogs.contactSupplier');
      confirm.order = $filter('ordersData')(orderObj.data);
      confirm.status = orderObj.status;
    }

    //Initialize
    init();

  }]);

})(angular);
