(function(angular){
  'use strict';


  var posts = angular.module('app.posts');


  // Provider
  posts.provider('$posts', [ function $posts() {

    var default_config = {
      locale: ''
    };

    this.locale = default_config.locale;

    this.$get = [function() {

      var locale = this.locale;

      return {
        getLocale: function() {
          return locale;
        },
        setLocale: function ( val ) {
          locale = val;
        }
      };
    }];


    this.setLocale = function(locale) {

      this.locale = locale || default_config.locale;
    };

  }]);


  posts.factory('$post', ['$posts', '$carbuApiService', '$q', '$filter', function ($posts, $carbuApiService, $q, $filter) {

    var locale = $posts.getLocale();
    var not_found = {redirect: {to: '404'}};


    function _clean_query(url) {
      var index_query = url.indexOf('?');

      if (index_query) {

        var queryString = url.substr(index_query);

        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

        for (var i = 0; i < pairs.length; i++) {

          var pair = pairs[i].split('=');

          if (!pair[1]) {
            url = url.replace(pair[0] + '=', '');
          }
        }
      }

      return url;
    }

    function _resolve_url (str, param_key, param_val) {

      if (angular.isString(str) && angular.isString(param_key)) {
        return str.replace(':' + param_key, (param_val ? param_val :''));
      }

      return '';
    }

    function _get_permalink( post, key, value, archive ) {

      return '/' + locale  + _resolve_url((archive === true ? post.archiveUrl : post.url), key, value);
    }

    /**
     * Get post object
     *
     * @param to_state_name
     * @param params
     * @returns {*}
     * @private
     */
    function _get(to_state_name, params) {

      var deferred = $q.defer();

      $carbuApiService.getProperties().then(
        function (properties) {

          var post_state = to_state_name.replace('posts.', '');

          var post = angular.isObject(properties.posts) ? properties.posts[post_state] : null;

          if (post) {

            //Resolve params in url element
            angular.forEach(post.elements, function (element, element_key) {
              angular.forEach(params, function (param, param_key) {
                post.elements[element_key].url = _resolve_url(element.url, param_key, param);
              });

              post.elements[element_key].url = _clean_query(post.elements[element_key].url);
            });

            post.state = {params: params};
            post.getPermalink = _get_permalink;

            deferred.resolve(post);
          }
          else {
            deferred.reject(not_found);
          }
        },
        function (error) {
          deferred.reject(not_found);
        }
      );
      return deferred.promise;
    }

    return {
      get: _get,
      getPermalink: _get_permalink,
      resolveUrl: _resolve_url
    };

  }]);

})(angular);
