(function(angular){
  'use strict';

  var app = angular.module('app.root');

  app.controller('mainCtrl', ['$scope', '$rootService', '$filter', 'templateUrls', function ( $scope, $rootService, $filter, templateUrls ) {

    var main = this;

    function init() {

      main.currentLocation = {};

      main.templateUrls = templateUrls;
      main.currentLocale = $rootService.currentLocale;
      main.localeSelector = $rootService.localeSelector;
      main.navOpen = false;
      main.alerts = $filter('newsAlert')($rootService.news);
    }

    this.closeAlert = function(index) {
      main.alerts.splice(index, 1);
    };

    this.removeAlert = function(index) {
      if (index === undefined) {
        main.alerts = [];
      }
      else {
        main.closeAlert(index);
      }
    };

    this.addAlert = function(alert) {
      if (alert.clear === true) {
        main.alerts = [];
      }
      main.alerts.push({type: alert.type, msg: alert.msg});
    };


    //On translation change start
    $scope.$on('rootCurrentLocaleChange', function (event, current_locale) {

      main.currentLocale = current_locale;
    });

    $scope.$on('$locationChangeSucess', function (event, new_location, old_location) {

      main.removeAlert();
    });

    $scope.$on('rootCurrentLocationChange', function (event, new_location, old_location) {

      main.currentLocation = new_location;

      main.alerts = $filter('newsAlert')($rootService.news);
    });


    //Initialize
    init();

  }]);

})(angular);
