(function(angular){
  'use strict';

  //Load Module
  var ngCarbuApi = angular.module('ngCarbuApi');

  ngCarbuApi.factory('$carbuApiOffers', [
    '$carbuApiResource',
    '$carbuApiStatus',
    '$carbuApiConst',
    '$filter',
    '$injector',
    '$q',
    '$log',
    function ( $carbuApiResource, $carbuApiStatus, $carbuApiConst, $filter, $injector, $q, $log) {

    var service = this;

    service.request = {};

    /**
     * Get Offers
     *
     * @param params
     * @param cancel
     * @private
     */
    function _get_offers(params, cancel) {
      var deferred = null;

      //Cancel last request if param true
      if ((cancel === undefined || cancel === true) && service.request.offers && typeof service.request.offers.$cancelRequest === 'function') {
        service.request.offers.$cancelRequest();
      }

      deferred = $q.defer();

      service.request.offers = $carbuApiResource.offers.query(params,
        function (success, getResponseHeaders) {

          deferred.resolve( angular.isArray(success) ? $filter('offersData')(success) : []);
        },
        function (error, getResponseHeaders) {
          $log.warn(error);
          deferred.resolve([]);
        }
      );

      return deferred.promise;
    }


    /**
     * Get Offer
     *
     * @param key
     * @private
     */
    function _get_offer(key) {

      var deferred = $q.defer();
      var result = {
        status: $carbuApiStatus.reset(),
        data: null
      };

      if (key && angular.isString(key) && key.length > 0) {

        $carbuApiResource.offers.get({key: key},
          function (success, getResponseHeaders) {

            result.data = $filter('offersData')(success.data ? success.data : success);

            //Validation data
            if (!result.data.location || !angular.isObject(result.data.location)) {
              delete result.data;
              result.status = $carbuApiStatus.setError({
                result: {
                  status: 404,
                  message: 'Not Found Location for this offer'
                }
              });
              deferred.reject(result);
            }
            else {

              result.status = $carbuApiStatus.setSuccess(success);
              deferred.resolve( result );
            }
          },
          function (error, getResponseHeaders) {
            result.status = $carbuApiStatus.setError(error);
            $log.warn(error);
            deferred.reject(result);
          }
        );
      }
      else {
        deferred.reject( result );
      }

      return deferred.promise;
    }


    /**
     * Get Requests offers
     *
     * @param params
     * @param cancel
     * @returns {*}
     * @private
     */
    function _get_requests(params, cancel) {

      var deferred = null;
      var result = {
        status: $carbuApiStatus.reset(),
        data: null
      };

      //Inject Authentification
      var $carbuApiAuth = $injector.get('$carbuApiAuth');

      params = params || {};
      params = angular.extend({
        status: $carbuApiConst.status.open,
        userKey: $carbuApiAuth.getUserKey(),
        userId: $carbuApiAuth.getUserId()
      }, params);

      if (angular.isString(params.userKey)) {

        //Cancel last request if param true
        if ((cancel === undefined || cancel === true) && service.request.requests && typeof service.request.requests.$cancelRequest === 'function') {
          service.request.requests.$cancelRequest();
        }

        deferred = $q.defer();

        service.request.requests = $carbuApiResource.requests.query(params,
          function (success, getResponseHeaders) {

            deferred.resolve( angular.isArray(success) ? $filter('requestsData')(success) : []);
          },
          function (error, getResponseHeaders) {
            $log.warn(error);
            deferred.resolve([]);
          }
        );
      }
      else {
        deferred.reject( result );
      }

      return deferred.promise;
    }


    /**
     * Get request offers
     *
     * @param key
     * @returns {*}
     * @private
     */
    function _get_request(key) {

      var deferred = $q.defer();
      var result = {
        status: $carbuApiStatus.reset(),
        data: null
      };

      //Inject Authentification
      var $carbuApiAuth = $injector.get('$carbuApiAuth');
      var params = {
        key: key,
        userKey: $carbuApiAuth.getUserKey(),
        userId: $carbuApiAuth.getUserId()
      };

      if (angular.isString(params.key) && params.key.length > 0 && angular.isString(params.userKey)) {

        $carbuApiResource.requests.get(params,
          function (success) {

            result.data = $filter('requestsData')(success.data ? success.data : success);

            //Validation data
            if (!result.data.offers || !angular.isObject(result.data.offers)) {
              delete result.data;
              result.status = $carbuApiStatus.setError({
                result: {
                  status: 404,
                  message: 'Not Found offers for this request'
                }
              });
              deferred.reject(result);
            }
            else {

              result.status = $carbuApiStatus.setSuccess(success);
              deferred.resolve( result );
            }
          },
          function (error, getResponseHeaders) {
            result.status = $carbuApiStatus.setError(error);
            $log.warn(error);
            deferred.reject(result);
          }
        );
      }
      else {
        deferred.reject( result );
      }

      return deferred.promise;
    }


    /**
     * Save Request for offer
     *
     * @param email
     * @param quantity
     * @param productId
     * @param areaCode
     * @param userKey
     * @returns {*}
     * @private
     */
    function _save_request(search, email) {

      var deferred = $q.defer();
      var params = search;

      //Inject Authentification
      var $carbuApiAuth = $injector.get('$carbuApiAuth');

      params.userKey = $carbuApiAuth.getUserKey();

      if (!angular.isString(params.userKey)) {
        params = angular.extend({ email: email }, search);
      }

      $carbuApiResource.requests.save(params).$promise.then(
        function (success) {

          deferred.resolve({
            status: $carbuApiStatus.setSuccess(success),
            data: (success.data ? success.data : success)
          });
        },
        function (error) {

          $log.warn(error);
          deferred.reject({
            status: $carbuApiStatus.setError(error),
            data: null
          });
        }
      );

      return deferred.promise;

    }

    /**
     * Public Api Exposed Services
     */
    return {
      getOffers: _get_offers,
      getOffer: _get_offer,
      getRequests: _get_requests,
      getRequest: _get_request,
      saveRequest: _save_request
    };
  }]);

})(angular);
