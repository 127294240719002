(function(angular){

  'use strict';

  // Create module
  var posts = angular.module('app.posts', [
    'ui.router',
    'ismobile'
  ]);


  //This configures the routes and associates each route with a view and a controller
  posts.config(['APP_CONFIG', '$stateProvider', function (APP_CONFIG, $stateProvider) {

      //Dynamic states posts from api properties.
      var post_state = {
        name: 'posts.**',
        parent: 'main',
        url: '/',
        lazyLoad: function($transition$) {

          var $carbuApiService = $transition$.injector().get('$carbuApiService');
          var $rootService = $transition$.injector().get('$rootService');

          return $carbuApiService.getProperties().then(
            function (properties) {

              //Define Post State
              var new_states = [
                {
                  name: 'posts',
                  parent: 'main',
                  url: '',
                  abstract: true,
                  template: '<ui-view/>'
                }
              ];

              //Define States from api properties.
              angular.forEach(properties.posts, function (post, name) {

                if (angular.isString(post.url) && (angular.isString(post.templateName) || angular.isString(post.templateUrl))) {

                  var new_params;

                  if (angular.isObject(post.params)) {

                    new_params = {};

                    angular.forEach(post.params, function (param, key) {

                      this[key] = {};

                      //Optional param
                      if (param.optional === true) {

                        delete param.optional;

                        this[key].value = null;
                        this[key].squash = true;
                      }

                      angular.extend(this[key], param);
                    }, new_params);
                  }


                  this.push({
                    name: 'posts.' + name,
                    url: post.url,
                    params: new_params,
                    templateUrl: post.templateName ? $rootService.getTemplateUrls('posts.' + post.templateName) : post.templateUrl,
                    controller: 'postCtrl',
                    controllerAs: 'post',
                    resolve: {
                      templateUrls: ['$rootService', function ($rootService) {
                        return $rootService.getTemplateUrls('posts.elements');
                      }],
                      postObj: ['$transition$', '$stateParams', '$post', function ($transition$, $stateParams, $post) {

                        var to_state = $transition$.to();

                        return $post.get(to_state.name, $stateParams);
                      }]
                    }
                  });
                }
              }, new_states);

              return { states: new_states };
            },
            function (error) {
              return { states: [] };
            }
          );
        }
      };

      $stateProvider.state(post_state);

}]);

})(angular);
