(function(angular){
  'use strict';


  // Load module
  var ngCarbuApi = angular.module('ngCarbuApi');

  //Auth Service Abstraction
  ngCarbuApi.factory('$carbuApiAuth', ['$carbuApiResource', '$carbuApi', '$carbuApiStatus', '$q', '$window', '$log', function ( $carbuApiResource, $carbuApi, $carbuApiStatus, $q, $window, $log ) {

    var _user_action_ids = $carbuApi.getRest().routes.users.actionIds;

    var _session = {
      type: $carbuApi.getSessionType(),
      key: $carbuApi.getName() + '.auth'
    };

    var _default_service = {
      auth: {
        status: null,
        data: null,
        session: null
      },
      user: {}
    };

    var service;


    /**
     * Set Session
     *
     * @param data
     * @private
     */
    function _set_session( data ) {

      service.auth.session = data;

      $log.debug('Set Auth Session to: ' + _session.type);
      $window[_session.type].setItem(_session.key, angular.toJson(service.auth.session));
    }

    /**
     * Get Session
     *
     * @returns {*}
     * @private
     */
    function _get_session() {

      service.auth.session = angular.fromJson($window[_session.type].getItem(_session.key));

      return service.auth.session;
    }

    /**
     * Remove Session
     *
     * @private
     */
    function _remove_session() {

      service.auth.session = null;
      $window[_session.type].removeItem(_session.key);

      $log.debug('Removed Auth from: ' + _session.type);
    }


    /**
     * Get Current User
     *
     * @returns {number}
     * @private
     */
    function _get_user_id() {

      return angular.isObject(service.auth.data) ? service.auth.data.id || 0 : 0;
    }


    /**
     * Get Current User Key
     *
     * @returns {number}
     * @private
     */
    function _get_user_key() {

      return angular.isObject(service.auth.session) && service.auth.session.key ? service.auth.session.key : null;
    }


    /**
     * Request
     *
     * @param type
     * @param endpoint
     * @param promise
     * @param object
     * @returns {Promise|*}
     * @private
     */
    function _request_defer(type, endpoint, promise, object) {
      var deferred = $q.defer();

      promise.then(
        function (response_success) {

          var result = {
            status: $carbuApiStatus.setSuccess(response_success),
            data: (response_success.data ? response_success.data : response_success)
          };

          if (type === 'auth') {
            switch (endpoint) {
              case 'signin':
                _set_session({
                  id: result.data.id || 0,
                  key: result.data.key || null
                });
                break;
              case 'logout':
                result = _default_service[type];
                result.status = $carbuApiStatus.reset();
                _remove_session();
                break;
            }

            result.session = _get_session();
          }

          if (angular.isObject(object)) {
            object[type] = result;
          }

          $carbuApiStatus.sendEvent('$carbuApi:' + type + '-' + endpoint + '-status', result);
          deferred.resolve(result);
        },
        function (response_error) {

          var result = {
            status: $carbuApiStatus.setError(response_error),
            data: null
          };

          if (angular.isObject(object)) {
            object[type] = result;
          }

          if (type === 'auth') {
            _remove_session();
          }

          $carbuApiStatus.sendEvent('$carbuApi:' + type + '-' + endpoint + '-status', result);
          deferred.reject(result);
        }
      );

      return deferred.promise;
    }


    /**
     * Get Authentication
     *
     * @returns {*|Promise}
     * @private
     */
    function _get_auth() {
      var deferred = $q.defer();

      if (!_get_user_key()) {
        deferred.reject(service.auth);
      }
      else if (_get_user_id()) {
        deferred.resolve(service.auth);
      }
      else {
        var action_id = _user_action_ids.me;

        return _request_defer('auth', 'get', $carbuApiResource.users.get({actionId: action_id, userKey: _get_user_key()}).$promise, service);
      }

      return deferred.promise;
    }


    /**
     * Login
     *
     * @param user
     * @returns {Promise|*}
     * @private
     */
    function _signin( user ) {

      var action_id = _user_action_ids.signin || null;

      var auth = angular.extend({
        email: '',
        password: ''
      }, user);

      return _request_defer('auth', 'signin', $carbuApiResource.users.save({actionId: action_id }, auth).$promise, service);
    }


    /**
     * Is logged in
     *
     * @returns {boolean}
     * @private
     */
    function _is_logged_in() {

      return _get_user_id() > 0;
    }


    /**
     * Logout
     *
     * @returns {Promise|*}
     * @private
     */
    function _logout() {

      var action_id = _user_action_ids.logout || null;

      return _request_defer('auth', 'logout', $carbuApiResource.users.delete({actionId: action_id}, {userKey: _get_user_key()}).$promise, service);
    }


    /**
     * Signup
     *
     * @param user
     * @returns {Promise|*}
     * @private
     */
    function _signup( user ) {

      var action_id = _user_action_ids.signup || null;

      user = angular.extend({
        email: '',
        password: ''
      }, user);

      return _request_defer('auth', 'signup', $carbuApiResource.users.save({actionId: action_id}, user).$promise, null);
    }


    /**
     * Confirm Email
     *
     * @param user
     * @returns {Promise|*}
     * @private
     */
    function _confirm( user ) {

      var action_id = _user_action_ids.confirm || null;

      user = {
        confirmUserKey: user.key,
        email: user.email
      };

      return _request_defer('auth', 'confirm', $carbuApiResource.users.update({actionId: action_id}, user).$promise, null);
    }


    /**
     * Forgotten Password
     *
     * @param user
     * @param fields
     * @returns {Promise|*}
     * @private
     */
    function _forgotten_password( user ) {

      var action_id = _user_action_ids.lostPassword || null;

      user = angular.extend({
        email: ''
      }, user);

      return _request_defer('auth', 'forgottenPassword', $carbuApiResource.users.save({actionId: action_id}, user).$promise, null);
    }


    /**
     * Reset Password
     *
     * @param user
     * @returns {Promise|*}
     * @private
     */
    function _reset_password( user ) {

      user = {
        setPasswordKeyCheck: user.key,
        password: user.password
      };

      return _request_defer('auth', 'resetPassword', $carbuApiResource.users.save({actionId: _user_action_ids.setPassword || null}, user).$promise, null);
    }


    /**
     * Update Password
     *
     * @param user
     * @returns {Promise|*}
     * @private
     */
    function _update_password( current_pass, new_pass ) {

      var user = {
        password: current_pass,
        newPassword: new_pass,
        userId: _get_user_id(),
        userKey: _get_user_key()
      };

      return _request_defer('user', 'resetPassword', $carbuApiResource.users.save({actionId: _user_action_ids.updatePassword || null}, user).$promise, null);
    }


    /**
     * Get User
     *
     * @returns {*|Promise}
     * @private
     */
    function _get_user() {
      var deferred = $q.defer();

      _get_auth().then(
        function (success) {

          _request_defer('user', 'getUser', $carbuApiResource.users.get({actionId: _user_action_ids.me, userKey: _get_user_key()}).$promise, service).then(
            function (user_success) {
              deferred.resolve(user_success);
            },
            function (user_error) {
              deferred.reject(user_error);
            }
          );
        },
        function (error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }


    /**
     * Update User
     *
     * @param user
     * @param fields
     * @returns {*|Promise}
     * @private
     */
    function _update_user( user ) {
      var deferred = $q.defer();

      _get_auth().then(
        function (success) {

          user = angular.extend({
            userKey: _get_user_key()
          }, user);

          _request_defer('user', 'updateUser', $carbuApiResource.users.update({id: _get_user_id()}, user).$promise, service).then(
            function (user_success) {
              deferred.resolve(user_success);
            },
            function (user_error) {
              $log.warn(user_error);
              deferred.reject(user_error);
            }
          );
        },
        function (error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }


    /**
     * Initialize
     */
    function init() {

      service = angular.copy(_default_service);
      service.auth.status = $carbuApiStatus.reset();
      service.auth.session = _get_session();
    }

    //Initialize
    init();


    /**
     * Public Api Exposed Services
     */
    return angular.extend(service, {

      getSession: _get_session,
      getUserId: _get_user_id,
      getUserKey: _get_user_key,
      getAuth: _get_auth,
      signin: _signin,
      isLoggedIn: _is_logged_in,
      logout: _logout,
      signup: _signup,
      confirm: _confirm,
      forgottenPassword: _forgotten_password,
      resetPassword: _reset_password,
      updatePassword: _update_password,
      getUser: _get_user,
      updateUser: _update_user
    });

  }]);

})(angular);
