(function(angular){
  'use strict';

  // Load module
  var componentAuth = angular.module('app.components.auth');

  componentAuth.controller('authCtrl', ['$scope', '$injector', '$log', '$timeout', '$rootService', '$carbuApiAuth', function ( $scope, $injector, $log, $timeout, $rootService, $carbuApiAuth ) {

    var auth = this;


    function init() {

      var _default_auth = angular.isObject($scope.authDefault) ? $scope.authDefault : {};

      auth.submitForm = angular.isDefined($scope.authSubmit) ? $scope.authSubmit : 'signin';
      auth.data = angular.isObject($scope.authData) ? $scope.authData : {};
      auth.fields = _default_auth.fields || {};
      auth.params = _default_auth.params || {};
      auth.alert =  {
        delay: 0.6,
        show: false,
        timeout: 0,
        type: 'alert',
        msg: ''
      };

      if (_default_auth.submitForm) {
        $timeout(function () {
          auth.submit();
        }, 1000);
      }
    }


    /**
     * Send Authentication
     */
    this.send = function( fields ) {

      var send_instance;

      auth.hideAlert();

      var params = angular.extend({}, auth.params, fields);

      send_instance = $carbuApiAuth[auth.submitForm](params);

      send_instance.then(
        function (success) {

          auth.showAlert(success.status.message, success.status.success);

          switch(auth.submitForm) {
            case 'signin':

              auth.current = success;

              $timeout(function () {
                auth.hideAlert();

                $rootService.setAuth(auth.current);

                $scope.onSuccess({$success: success, $authParams: params});
              }, 2000);

              break;
            default:
              $scope.onSuccess({$success: success, $authParams: params});
          }

          return success;
        },
        function (error) {

          auth.showAlert(error.status.message);

          $scope.onError({$error: error, $authParams: params});

          return error;
        }
      );

      return send_instance;
    };



    /**
     * Hide Alert
     *
     */
    this.hideAlert = function() {

      var timeout = auth.alert.show ? ((auth.alert.delay * 1000) + 50) : 0;

      auth.alert.show = false;
      auth.alert.timeout = timeout;
    };


    /**
     * Show Alert
     *
     * @param success
     * @param type
     */
    this.showAlert = function(msg, success) {

      $timeout(function () {
        auth.alert.type = (success === true ? 'success' : 'alert');
        auth.alert.msg = msg;
        auth.alert.show = true;
      }, auth.alert.timeout);
    };

    /**
     * Submit
     */
    this.submit = function ( form ) {

      if (form) {
        form.$setTouched();
        form.$validate();
      }


      if ((!form ||form.$valid) && auth.submitForm) {

        return auth.send(auth.fields);
      }

      return false;
    };


    /**
     * Execute close from outside isolate scope.
     */
    this.close = function () {

      auth.hideAlert();

      $scope.onClose({$authFields: auth.fields});

      auth.fields = {};
    };

    //Initialize
    init();

  }]);

})(angular);
