(function(angular){

  'use strict';

  // Load module
  var ngCarbuApi = angular.module('ngCarbuApi');


  ngCarbuApi.filter('userData', ['$filter', function( $filter ) {

    /**
     * Filter offers
     *
     * @param array offers
     * @return array
     */
    return function(user) {

      var data = user;

      /**
       * Parse User
       *
       * @param order
       * @returns {{id: *, key: *, email, firstName: (*|Document.signupForm.firstName|Document.firstName), lastName: (*|Document.lastName), company: *, phone: (*|Document.phone), vat: (*|Document.vat), address1: (*|Document.address1), address2: (*|Document.address2), postalCode: *, city: *, country: *, comment: *}}
       * @private
       */
      function _parse( user ) {

        var _new_user = {
          supplier: {}
        };

        //Parse auth object
        angular.forEach(user, function (value, key) {

          var _new_key = key;
          var _in_key;

          //Mapping key
          switch (_new_key) {
            case 'companyName':
              _new_key = 'company';
              break;
            case 'cityName':
              _new_key = 'city';
              break;
          }

          if (_in_key) {
            this[_in_key][_new_key] = value;
          }
          else {
            this[_new_key] = value;
          }
        }, _new_user);

        return _new_user;
      }

      if (angular.isObject(user)) {
        data = _parse(user);
      }

      return data;

    };

  }]);

})(angular);
