(function(angular){
  'use strict';


  var app = angular.module('app.root');

  app.factory('$stateAuth', [
    '$rootService',
    '$q',
    '$state',
    function ($rootService, $q, $state) {

    return {
      get: function (to_state, from_state) {
        var deferred = $q.defer();

        var is_to_auth = to_state.name.indexOf('auth') === 0;
        var is_to_account = to_state.name.indexOf('account') === 0;
        var is_from_account = from_state.name.indexOf('account') === 0;

        if (to_state.protected || is_to_auth) {

          $rootService.getAuth().then(
            function (success) {

              //Prevent auth state if loggedin. redirect account dashboard
              if (is_to_auth) {
                deferred.reject($state.get('account.dashboard'));
              }
              else {
                deferred.resolve(to_state);
              }
            },
            function (error) {

              //Prevent from account to account redirect home (logout on account state)
              if (is_from_account && is_to_account) {
                deferred.reject($state.get('home'));
              }
              //Prevent to account redirect auth signin
              else if ( is_to_account ) {
                deferred.reject($state.get('auth.signin'));
              }
              else {
                deferred.resolve(to_state);
              }
            }
          );
        }
        else {
          deferred.resolve(to_state);
        }

        return deferred.promise;
      }
    };
  }]);

})(angular);
