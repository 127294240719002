(function(angular){

  'use strict';

  // Create module
  var pageOrder = angular.module('app.pages.order', [
    'ui.router',
    'ui.mask',
    'ui.validate',
    'focus-if',
    'ng-slide-down',
    'ismobile'
  ]);


  //This configures the routes and associates each route with a view and a controller
  pageOrder.config(['APP_CONFIG', '$stateProvider', 'isMobileProvider', function (APP_CONFIG, $stateProvider, isMobileProvider) {

    /* Order Abstract State */
    var state_order = {
      name: 'order',
      parent: 'main',
      url: '/order',
      abstract: true,
      template: '<ui-view/>',
      controller: 'orderCtrl',
      controllerAs: 'order',
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.order');
        }],
        searchObj: [ '$rootService', function ($rootService) {

          var search = $rootService.getSearch();

          return search.params;
        }]
      }
    };

    /* Order Checkout State */
    var state_order_checkout = {
      name: 'order.checkout',
      url: '/checkout/:key',
      params: {
        key: {
          type: 'string',
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout.tpl.html',
          controller: 'orderCheckoutCtrl',
          controllerAs: 'checkout'
        },
        '@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout/main.tpl.html'
        },
        'sidebar@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout/sidebar.tpl.html'
        },
        'details@order.checkout': {
          templateUrl: (isMobileProvider.phone) ? APP_CONFIG.templateUrl + 'pages/order/checkout/details-mobile.tpl.html' : APP_CONFIG.templateUrl + 'pages/order/checkout/details.tpl.html'
        },
        'nav@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/nav.tpl.html'
        },
        'user@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout/states/user.tpl.html'
        },
        'info@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout/states/info.tpl.html'
        },
        'ship@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout/states/ship.tpl.html'
        },
        'order@order.checkout': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/checkout/states/order.tpl.html'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.order.checkout');
        }],
        globalTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('global');
        }],
        offerObj: ['$q', '$stateParams', '$state', '$transition$', '$rootService', '$carbuApiOffers', function($q, $stateParams, $state, $transition$, $rootService, $carbuApiOffers) {

          var deferred = $q.defer();
          var from_state = $transition$.from();
          var redirect_state_name = from_state.name.length ? from_state.name : 'home';

          //Prevent key null
          if (!$stateParams.key) {

            deferred.reject({redirect: {to: redirect_state_name, options: {location: 'replace'}}});
            return deferred.promise;
          }

          //Resolve success and error.
          $carbuApiOffers.getOffer($stateParams.key).then(
            function (success) {

              deferred.resolve($rootService.setCart(success.data));
            },
            function (error) {
              //Set cart to empty
              $rootService.setCart();

              deferred.reject({redirect: {to: redirect_state_name, options: {location: 'replace'}}});
            }
          );

          return deferred.promise;
        }],
        authObj: ['$rootService', function ($rootService) {

          //Resolve Auth success and error
          return $rootService.getAuth().then(
            function (success) {
              return success;
            },
            function (error) {
              return error;
            }
          );
        }]
      }
    };


    /* Order Confirm State */
    var state_order_confirm = {
      name: 'order.confirm',
      url: '/confirm/:key',
      params: {
        key: {
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/confirm.tpl.html',
          controller: 'orderConfirmCtrl',
          controllerAs: 'confirm'
        },
        '@order.confirm': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/confirm/main.tpl.html'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.order.confirm');
        }],
        globalTemplateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('global');
        }],
        orderObj: ['$q', '$stateParams', '$state', '$carbuApiOrders', function($q, $stateParams, $state, $carbuApiOrders) {

          var deferred = $q.defer();

          console.log('Resolve Confirm');
          console.log($stateParams);

          //Prevent key null
          if (!$stateParams.key) {

            deferred.reject({redirect: {to: 'home', options: {location: 'replace'}}});
            return deferred.promise;
          }

          $carbuApiOrders.getOrder($stateParams.key).then(
            function (success) {
              deferred.resolve(success);
            },
            function (error) {

              deferred.reject({redirect: {to: 'order.error', toParams: {order: error}, options: {location: 'replace'}}});
            }
          );

          return deferred.promise;
        }]
      }
    };


    /* Order Pending State */
    var state_order_pending = {
      name: 'order.pending',
      url: '/pending',
      params: {
        order: {
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/pending.tpl.html',
          controller: 'orderPendingCtrl',
          controllerAs: 'pending'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.order.pending');
        }],
        orderObj: ['$q', '$stateParams', '$state', function($q, $stateParams, $state) {

          var deferred = $q.defer();

          //Prevent order null
          if (!$stateParams.order) {

            deferred.reject({redirect: {to: 'home', options: {location: 'replace'}}});
          }
          else {
            deferred.resolve($stateParams.order);
          }

          return deferred.promise;
        }]
      }
    };


    /* Order Error State */
    var state_order_error = {
      name: 'order.error',
      url: '/error',
      params: {
        order: {
          value: null
        }
      },
      views: {
        '': {
          templateUrl: APP_CONFIG.templateUrl + 'pages/order/error.tpl.html',
          controller: 'orderErrorCtrl',
          controllerAs: 'error'
        }
      },
      resolve: {
        templateUrls: ['$rootService', function ($rootService) {
          return $rootService.getTemplateUrls('pages.order.error');
        }],
        orderObj: ['$q', '$stateParams', '$state', function($q, $stateParams, $state) {

          var deferred = $q.defer();

          //Prevent order null
          if (!$stateParams.order) {

            deferred.reject({redirect: {to: 'home', options: {location: 'replace'}}});
          }
          else {
            deferred.resolve($stateParams.order);
          }

          return deferred.promise;

        }]
      }
    };

    $stateProvider.state(state_order);
    $stateProvider.state(state_order_checkout);
    $stateProvider.state(state_order_confirm);
    $stateProvider.state(state_order_pending);
    $stateProvider.state(state_order_error);

}]);

})(angular);
