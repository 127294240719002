(function(angular){
  'use strict';

  // Load module
  var posts = angular.module('app.posts');


  /**
   * Single Controller
   */
  posts.controller('postCtrl', ['$scope', '$rootScope', '$log', 'isMobile', 'templateUrls', 'postObj', function ( $scope, $rootScope, $log, isMobile, templateUrls, postObj) {

    var post = this;

    function init() {

      angular.extend(post, postObj);
    }

    this.getTemplateUrl = function( name ) {

      return templateUrls[name];
    };


    //Initialize
    init();

  }]);

})(angular);
