(function(angular){

  'use strict';

  // Load module
  var ngCarbuApi = angular.module('ngCarbuApi');


  ngCarbuApi.filter('offersData', ['$filter', function( $filter ) {

    /**
     * Filter offers
     *
     * @param array offers
     * @return array
     */
    return function(offers) {

      var data = offers;

      /**
       * Parse Offer
       *
       * @param offer
       * @returns {Object}
       * @private
       */
      function _parse( offer ) {

        offer.isAction = offer.isAction || (angular.isString(offer.type) ? offer.type.toUpperCase() === 'FLASH' : false);

        if (angular.isObject(offer.product)) {
          angular.extend(offer.product,
            {
              title: offer.product.title || offer.product.name,
              type: offer.product.type || offer.product.category,
              price: offer.unitPrice,
              quantity: offer.quantity
            }
          );
        }

        if (angular.isObject(offer.supplier)) {


          var _rating_max = 5;
          var _supplier_rating = {
            max: _rating_max,
            value: angular.isObject(offer.supplier.rating) && offer.supplier.rating.score ? _rating_max * parseFloat(offer.supplier.rating.score) : 0,
            score: angular.isObject(offer.supplier.rating) && offer.supplier.rating.score ? parseFloat(offer.supplier.rating.score) : 0,
            count: angular.isObject(offer.supplier.rating) && offer.supplier.rating.count ? parseFloat(offer.supplier.rating.count) : 0
          };

          angular.extend(offer.supplier.rating || {}, _supplier_rating);
          angular.extend(offer.supplier,
            {
              title: offer.supplier.title || offer.supplier.name
            }
          );
        }

        return offer;
      }

      if (angular.isArray(offers)) {
        data = [];

        angular.forEach(offers, function(obj, key) {
          this.push(_parse(obj));
        }, data);

      }
      else if (angular.isObject(offers)) {
        data = _parse(offers);
      }

      return data;

    };

  }]);

})(angular);
