(function(angular){
  'use strict';

  // Load module
  var pageAccount = angular.module('app.pages.account');

  pageAccount.controller('accountOrderCtrl', ['$scope', 'orderObj', 'templateUrls', 'globalTemplateUrls', function ( $scope, orderObj, templateUrls, globalTemplateUrls ) {

    var order = this;

    function init() {

      angular.extend(order, orderObj);

      order.templateUrls = templateUrls;
      order.globalTemplateUrls = globalTemplateUrls;
    }


    //Initialize
    init();

  }]);

})(angular);
