(function(angular){
  'use strict';

  var app = angular.module('app.root');

  /**
   * Get Element Size Width and Height width debounce
   *
   * This approach watches an elements height and width and assigns it to a variable
   * on the scope provided on your elements attribute
   * https://stackoverflow.com/questions/19048985/angularjs-better-way-to-watch-for-height-change
   */
  app.directive('selectPlaceholder', ['$interpolate', function($interpolate) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, elem, attrs, ctrl) {

        var defaultOptionTemplate;

        scope.defaultOptionText = attrs.selectPlaceholder || '- Please Choose -';

        defaultOptionTemplate = '<option value="" disabled selected style="display: none;">{{defaultOptionText}}</option>';

        elem.prepend($interpolate(defaultOptionTemplate)(scope));
      }
    };
  }]);

})(angular);
